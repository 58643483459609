export default function Borrar({ p10, fontSize, right, top, children, name, ...props }) {
  const [rowP10, colP10] = p10.split('x').map(part => parseInt(part.trim()));

  const aspect = `${colP10 * 32}/${rowP10 * 16}`
  const width = Number(((colP10 * 32) / 4.2).toFixed(2))
  const widthClass = `${width}%`
  // const relComponet = `h-1/${rowP10}`
  const relComponet = `h-1/3`
  // const rightP10 = `${parseFloat(right)}%`
  const rightText = `${parseFloat(right) + 25.5}%`
  const childrenCount = Array.isArray(children) ? children.length : 1
  const positionText = childrenCount !== 1 ? { left: '3.033%', justifyContent: "start" } : { right: rightText }

  const divs = []

  for (let i = 1; i <= childrenCount / rowP10; i++) {
    const childs = []
    if (childrenCount !== 1) {
      for (let j = 0; j < rowP10; j++) {
        const index = (i - 1) * rowP10 + j
        childs.push(
          <span key={index} className={`w-full flex justify-center items-center ${relComponet}`}>
            {children[index]}
          </span>
        )
      }
    } else {
      childs.push(
        <span key={0} className={`w-full flex justify-center items-center ${relComponet}`}>
          {children}
        </span>
      )
    }
    divs.push(
      <div
        key={`${name}__${i}`}
        className="border-2 border-black bg-[#282828] flex flex-col items-center justify-center text-[#fd0000] font-Poppins font-bold text-3xl absolute whitespace-nowrap overflow-hidden"
        style={{
          fontSize: fontSize,
          WebkitTextStrokeWidth: "0px",
          WebkitTextStrokeColor: "black",
          width: widthClass,
          right: `${25.19 * (childrenCount / rowP10 - i) + parseFloat(right)
            }%`,
          top: `${parseFloat(top)}%`,
          aspectRatio: aspect,
        }}
      >
        {childs}
      </div>
    );
  }

  return (
    <>
      {name &&
        <span
          className="flex items-center justify-end font-Poppins font-bold absolute whitespace-nowrap overflow-hidden text-[#4D4D4D]"
          style={{
            fontSize: fontSize,
            width: widthClass,
            top: `${parseFloat(top)}%`,
            ...positionText,
            aspectRatio: aspect,
          }}
          {...props}
        >
          {name}
        </span>
      }

      {divs}
    </>
  );
}