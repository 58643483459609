export const datetimeUTCPeru = ({datetime}) => {
    if(datetime === 'No registrado' || datetime === '-----'){
        return datetime
    }
    const options = {
        timeZone: 'America/Lima', // Ejemplo para UTC-05
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };

    const date = new Date(`${datetime}`);
    return date.toLocaleString('es-PE', options);
}

export const maskPassword = ({ value }) => {    
    return '■ ■ ■ ■ ■ ■'
}