import React, { useEffect, useRef, useState } from "react";
import P10textR from "./P10textR";
import P10textG from "./P10textG";
import LogoHudbay from "../../static/img/HUDBAYLOGO.svg";
import "./borrar.css";
import { formatCreateP10 } from "../helpers/formatCreateP10";
import P10Skeleton from "./P10Skeleton";

export default function PanelGrafico({ className, datos }) {
  const parentDivRef = useRef(null);
  const [fontSize, setFontSize] = useState(null);
  const [dataArray, setDataArray] = useState(formatCreateP10());
  const [classAnimate, setClassAnimate] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setFontSize(parentDivRef.current.clientHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;

      if (scrollWidth > clientWidth) {
        setClassAnimate("animate-aniMarquee  !flex !justify-start");
      } else {
        const textLength = container.querySelector("span").textContent.length;
        if (textLength >= 21 && scrollWidth === clientWidth) {
          setClassAnimate("animate-aniMarquee  !flex !justify-start");
        } else {
          setClassAnimate("flex justify-center");
        }
      }
    }
    setDataArray(formatCreateP10(datos))
  }, [datos]);

  return (
    <>
      <div className="w-full flex flex-col gap-0">

        <div className="bg-gradient-to-b from-[#F2911B] to-[#D91A1A] py-3 lg:py-4 rounded-t-xl lg:rounded-t-2xl">
          <h1 className="text-left font-bold text-lg lg:text-2xl antialiased text-white px-4">
            VISTA PANEL
          </h1>
        </div>
        <div className={`aspect-[4105/1995] bg-white  ${className}`} >
          <div className="w-full h-full border-4 lg:border-8 border-[#B80D01] relative flex justify-center items-center">
            {/* ---------------------------  ANUNCIO Y TITULO  --------------------------- */}
            <img
              className="w-[27%] absolute top-[13.5%] left-[2.7%]"
              src={LogoHudbay}
              alt="LogoSouthern"
            />
            <span
              className="w-fit aspect-[6/1] flex items-center justify-end font-Poppins font-bold  absolute top-[0%] right-[2.8%] whitespace-nowrap overflow-clip"
              style={{ fontSize: fontSize * (4 / 9) }}
            >
              MINERA-CONSTANCIA
            </span>
            <span
              className="w-[62.36%] aspect-[8/1] lg:border-2 border-black bg-[#282828] flex items-center justify-center text-[#00fd00] font-Poppins font-bold text-3xl absolute top-[11.28%] right-[7.19%] whitespace-nowrap overflow-hidden"
              ref={parentDivRef}
              style={{
                fontSize: fontSize,
                WebkitTextStrokeWidth: "0px",
                WebkitTextStrokeColor: "black",
              }}
            >
              <div
                className={`w-full whitespace-nowrap ${classAnimate}`}
                ref={containerRef}
              >
                <span className="inline-block ml-3">{datos.titulo}</span>
              </div>
            </span>

            {dataArray[1].state ? (
              dataArray[0].map((item, index) => {
                const Component = item.color === "green" ? P10textG : P10textR;
                return (
                  <Component
                    key={index}
                    p10={item.p10}
                    fontSize={fontSize / 2}
                    right={item.right}
                    top={item.top}
                  >
                    <span>{item.text}</span>
                  </Component>
                );
              })
            ) : (
              dataArray[0].map((item, index) => {
                return (
                  <P10Skeleton
                    key={index}
                    p10={item.p10}
                    fontSize={fontSize / 2}
                    right={item.right}
                    top={item.top}
                  />
                );
              })
            )
            }

          </div>
        </div>
      </div>
    </>
  );
}
