export const formatRealtimeData = (dataWeb) => {
    try {
        const trimmedData = Object.fromEntries(
            Object.entries(dataWeb).map(([key, value]) => [key, typeof value === 'string' ? value === ' ' ? value : value.trim() : value])
        );
        const camposCompletos = {
            0: `~${trimmedData.campoFecha || ' '}~${trimmedData.campoTajo || ' '}~${trimmedData.campoBanco || ' '}~${trimmedData.campoFase || ' '}~${trimmedData.campoProyecto || ' '}~${trimmedData.campoHora || ' '}~`,
            1: trimmedData.tajo1Tajo ? trimmedData.tajo1Hora : '--:--',
            2: trimmedData.tajo1Tajo ? trimmedData.tajo1Proyecto : '--',
            3: trimmedData.tajo1Tajo ? trimmedData.tajo1Fase : '--',
            4: trimmedData.tajo1Tajo ? trimmedData.tajo1Banco : '--',
            5: trimmedData.tajo1Tajo ? trimmedData.tajo1Tajo : (trimmedData.tajo1Tajo || trimmedData.tajo2Tajo ? '--':'SIN'),
            6: trimmedData.tajo1Fecha,
            7: trimmedData.tajo2Tajo ? trimmedData.tajo2Hora : '--:--',
            8: trimmedData.tajo2Tajo ? trimmedData.tajo2Proyecto : '--',
            9: trimmedData.tajo2Tajo ? trimmedData.tajo2Fase : '--',
            10: trimmedData.tajo2Tajo ? trimmedData.tajo2Banco : '--',
            11: trimmedData.tajo2Tajo ? trimmedData.tajo2Tajo : (trimmedData.tajo2Tajo || trimmedData.tajo1Tajo ? '--':'VOLADURA'),
            12: trimmedData.titulo,
        }
        return camposCompletos
    } catch (error) {
        console.error('Error en formatData:', error);
        return {};
    }
}
