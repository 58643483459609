import { TfiClose } from 'react-icons/tfi';
import RingLoader from "react-spinners/RingLoader";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function Modal({ clickBtn, closeModal, state = false, dataCheck, dataSelect, handleCheckBoxs }) {
  return (
    <>
      <div
        className={`w-screen h-screen fixed overflow-hidden bg-[#000000dd] top-0 right-0 flex justify-center items-center z-[52]`}
      >
        <div className="w-[30%] h-auto bg-white flex flex-col justify-center items-center rounded-2xl shadow-[0px_0px_25px_2px_#2e354d] max-[1200px]:w-[40%] max-[1000px]:w-[50%] max-[800px]:w-[70%] max-[550px]:w-[90%]">
          <span className="header w-full px-5 pt-5 font-Poppins text-[#4D4D4D] uppercase text-2xl flex justify-center items-center relative">
            <InformationCircleIcon className="h-12 w-12" aria-hidden="true" />
            <TfiClose
              size="1.2rem"
              className="absolute right-5 top-5 cursor-pointer"
              onClick={closeModal}
            />
          </span>
          <span className="body w-full max-h-[30rem] px-5 py-3 font-Poppins text-[#4D4D4D] font-normal overflow-y-auto text-center flex justify-center items-center relative">
            {state ? (
              <RingLoader color="#B40D01" />
            ) : (
              <div className='flex flex-col justify-center items-center'>
                ¿Estás seguro que desea modificar el siguiente panel informativo?

                <FormGroup className='my-4'>
                  {Object.values(dataCheck).map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox
                        defaultChecked={index === dataSelect.IndexData}
                      />}
                      label={item.dataInfo.Name}
                      disabled={index === dataSelect.IndexData}
                      onChange={(e) => handleCheckBoxs(e.target,index)}
                    />
                  ))}
                </FormGroup>
              </div>

            )}
          </span>
          <span className="footer w-full px-5 pb-5 pt-2 h-fit flex justify-center items-center relative ">
            <button
              className={`flex justify-center items-center w-fit right-2 px-4 py-2 rounded-lg bg-white text-[#2E354D] border border-[#2E354D] font-Poppins font-medium hover:bg-[#2E354D] hover:text-white focus:bg-[#2E354D] focus:text-white transition-all ease-in-out delay-100 ${state && "animate-aniBtn !bg-[#2E354D] !text-white"
                }`}
              disabled={state}
              onClick={clickBtn}
            >
              {state ? "Subiendo información" : "Sí, estoy seguro"}
            </button>
          </span>
        </div>
      </div>
    </>
  );
}
