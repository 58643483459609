import { useEffect, useState } from "react"
import Alert from '@mui/material/Alert';

export default function AlertMUI({ Alerts, hanldeAlerts }) {
  const [openAlerts, setOpenAlerts] = useState([]);

  useEffect(() => {
    setOpenAlerts(Alerts);
    if (Alerts.length !== 0) {
      const timer = setTimeout(() => {
        hanldeAlerts([])
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [Alerts, hanldeAlerts]);

  const closeAlert = (index) => {
    hanldeAlerts(prevAlerts => prevAlerts.filter((_, i) => i !== index))
  };

  return (
    <>
      <div className="absolute top-[-1.5rem] right-2 z-50 flex flex-col gap-5 items-end">
        {openAlerts.map((alert, index) => (
          <Alert
            key={index}
            className={`w-fit !rounded-xl animate-slide-in ${alert.alert === "Error" ? '!shadow-[4px_4px_20px_-2px_#f00]' : '!shadow-[4px_4px_20px_-2px_#007d00]'} me-2`}
            variant="filled"
            severity={alert.alert === 'Error' ? "error" : "success"}
            onClose={() => closeAlert(index)}
          >
            {alert.textAlert ? alert.textAlert : alert.alert === 'Error' ? 'Faltan completar datos' : 'Datos guardados exitosamente'}
          </Alert>
        ))}
      </div>
    </>
  )
}
