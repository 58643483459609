import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const formatHour = (timeString) => {
    if (!timeString) return timeString;
    const arbitraryDate = '2022-04-17';
    const dateTimeString = `${arbitraryDate} ${timeString}`;
    const formattedDateTime = dayjs(dateTimeString, 'YYYY-MM-DD hh:mm A');
    return formattedDateTime.format('YYYY-MM-DDTHH:mm');
}

export default function InputFormPanelMUI({ defaultValue, onInputChange, type, labelItem, ...props }) {
    const [textInput, setTextInput] = useState(defaultValue);
    const [errorTime, setErrorTime] = useState(!defaultValue ? true : false);

    useEffect(() => {
        setTextInput(defaultValue);
    }, [defaultValue]);

    const handleChange = ({ target: { name, value } }) => {
        if (!value.includes('~')) {
            setTextInput(value)
            onInputChange({ [name]: value });
        }
    };

    const handleChangeTime = (newValue) => {
        if (newValue) {
            onInputChange({ [props.name]: newValue.format('hh:mm A') }); // Formato 12 horas
            setTextInput(newValue.format('hh:mm A'));
        }
    };

    return (
        <>
            {type === 'time' ? (

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>

                        <TimePicker
                            required
                            reduceAnimations
                            className={`!w-full`}
                            value={textInput ? dayjs(formatHour(textInput)) : null}
                            label={`${labelItem} *`}
                            onError={(errorVal) => { setErrorTime((errorVal || textInput === 'Invalid Date') ? true : false) }}
                            onChange={handleChangeTime}
                            onClose={() => { setErrorTime(!textInput ? true : false) }}
                            sx={{
                                '& .MuiFormLabel-root': {
                                    color: errorTime ? '#d32f2f' : '#00000099',
                                },
                                '& .MuiIconButton-root': {
                                    color: errorTime ? '#d32f2f' : '#00000099',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: errorTime ? '#d32f2f' : '#e0e0e0',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: errorTime ? '#d32f2f' : '#b0b0b0',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: errorTime ? '#d32f2f' : '#1976d2',
                                    },
                                },
                                '& .MuiOutlinedInput-root.Mui-error': {
                                    '& fieldset': {
                                        borderColor: '#d32f2f',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#d32f2f',
                                    },
                                },
                            }}
                            {...props}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            ) : (
                <TextField
                    error={!defaultValue}
                    value={textInput}
                    label={labelItem}
                    onChange={handleChange}
                    type={type}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#e0e0e0',
                            },
                            '&:hover fieldset': {
                                borderColor: '#b0b0b0',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#1976d2',
                            },
                        },
                        '& .MuiOutlinedInput-root.Mui-error': {
                            '& fieldset': {
                                borderColor: '#d32f2f',
                            },
                            '&:hover fieldset': {
                                borderColor: '#d32f2f',
                            },
                        },
                    }}
                    {...props}
                />
            )}
        </>
    );
}
