import React, { useState, useCallback } from 'react'
import { useAuth } from "../context/authContext";
import PanelGrafico from '../components/PanelFigura/PanelGrafico';
import FormsPanel from '../components/FormsPanel';
import BackgroundStyle from "../components/shared/BackgroundStylePage";
import Modal from "../components/ui/Modal";
import AlertMUI from '../components/ui_MUI/AlertMUI';
import { useData } from '../context/dataContext';

const PanelDetail = () => {
    const { dataFirebase, updateData, dataSelect } = useData()
    const { loading } = useAuth()
    const [stateModal, setStateModal] = useState(false)
    const [stateModalLoading, setStateModalLoading] = useState(false)
    const [alert, setAlert] = useState([])
    const [dataForm, setDataForm] = useState({})
    const [dataCheckBoxs, setDataCheckBoxs] = useState([])

    const onModifyDatos = (dataForm) => {
        setStateModal(true)
        setDataForm(dataForm)
        setDataCheckBoxs({ [dataSelect.IndexData]: true })
        // console.log(dataForm)
        // console.log({ [dataSelect.IndexData]: dataForm })
    }

    const handleButtonClick = async () => {
        try {
            setStateModalLoading(true)
            const response = await updateData(dataForm, dataCheckBoxs);
            if (!response) {
                setStateModal(false)
                setStateModalLoading(false)
                setAlert([{ alert: 'Error', textAlert: 'No se pudo completar los cambios' }])
                return;
            }
            setStateModal(false)
            setStateModalLoading(false)
            setAlert([{ alert: 'Success', textAlert: 'Datos Modificados Exitosamente!!!' }])
        } catch (errors) {
            console.error(errors)
            setStateModal(false)
            setStateModalLoading(false)
            setAlert([{ alert: 'Error', textAlert: 'No se pudo completar los cambios' }])
        }
    }

    const handleAlerts = useCallback((newAlerts) => {
        setAlert(newAlerts);
    }, [setAlert]);

    const handleCheckBoxs = (target, index) => {
        setDataCheckBoxs(prevState => ({ ...prevState, [index]: target.checked }));
    };

    if (loading) {
        return (<>
            <BackgroundStyle></BackgroundStyle>
        </>)
    }

    return (
        <>
            {dataSelect && !loading ? (
                <>
                    {alert.alert !== '' &&
                        <AlertMUI Alerts={alert} hanldeAlerts={handleAlerts} />
                    }
                    {stateModal && <Modal
                        clickBtn={handleButtonClick}
                        closeModal={() => { setStateModal(false) }}
                        state={stateModalLoading}
                        dataCheck={dataFirebase}
                        dataSelect={dataSelect}
                        handleCheckBoxs={handleCheckBoxs}
                    />}
                    <div className={`w-full h-full flex flex-col gap-8 items-center bg-[#f9f9f9] px-4 py-8 overflow-auto`}>
                        <div className='flex gap-5 w-full h-full justify-around items-center max-[900px]:flex-col max-[900px]:h-fit '>
                            <PanelGrafico className="w-full" datos={dataSelect} />
                            <FormsPanel className="w-[70%] max-[900px]:w-[80%] max-[700px]:w-[90%] max-[450px]:w-[100%] mb-5" datos={dataSelect} onModifyDatos={onModifyDatos} />
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full h-screen flex flex-col gap-8 items-center bg-[#f9f9f9] pt-[4rem] pb-[0rem]">
                </div>
            )}
        </>
    )
}

export default PanelDetail