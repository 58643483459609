import { useState } from "react";
import Alert from "../components/ui/Alert";
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Skeleton } from "@mui/material";
import { useData } from "../context/dataContext";

const Paneles = () => {
    const { dataFirebase, loadData } = useData()
    const [alert, setAlert] = useState('')
    const navigate = useNavigate();


    return (
        <>
            {!loadData ? (
                <>
                    {alert !== '' && <Alert stateAlert={alert} closeAlert={() => { setAlert('') }} />}
                    <div className={`w-full h-full flex flex-col gap-8 bg-[#f9f9f9] px-4 py-8 overflow-auto justify-center items-center`}>
                        <div className='flex flex-wrap gap-10 w-full h-full justify-around items-center max-[900px]:flex-col max-[900px]:h-fit '>
                            {

                                Object.values(dataFirebase).map((item, index) => {
                                    return (
                                        <Card
                                            key={`card-${index}`}
                                            raised
                                            onClick={() => navigate(item.dataInfo.Path)}
                                            className='!rounded-2xl !w-full !cursor-pointer'
                                            sx={{ maxWidth: 400 }}>
                                            <CardMedia
                                                sx={{ height: 200 }}
                                                image={`${item.dataInfo.Image}`}
                                                title={`${item.dataInfo.Name}`}
                                            />
                                            <CardContent className='text-center !p-2'>
                                                <span className='font-Poppins font-bold text-[2rem] text-black uppercase'>
                                                    {item.dataInfo.Name}
                                                </span>
                                            </CardContent>
                                        </Card>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            ) : (
                <div className={`w-full h-full flex flex-col gap-8 items-center bg-[#f9f9f9] px-4 py-8 overflow-auto`}>
                    <div className='flex flex-wrap gap-5 w-full h-full justify-around items-center max-[900px]:flex-col max-[900px]:h-fit '>
                        <Card className='!rounded-2xl !w-full !cursor-wait' raised sx={{ maxWidth: 400 }}>
                            <Skeleton variant="rectangular" width="100%" height={200} />
                            <CardContent className='!flex !p-2 !justify-center'>
                                <Skeleton className='!text-center' variant="text" width="80%" height={40} />
                            </CardContent>
                        </Card>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default Paneles