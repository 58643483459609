import React, { useCallback, useRef, useState } from "react";
// import { useEffect } from "react";
import InputFormPanel from "./ui/InputFormPanel";
import Carousel from "./ui/Carousel";
import ButtonGroup from "./ui/ButtonGroup";
import { ButtonKeyFrame } from "./ui";
import { CalendarDaysIcon, TableCellsIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import CheckSelectInput from "./ui/CheckSelectInput";
import useFormPanel from "./hooks/useFormPanel";
import InputFormPanelMUI from "./ui_MUI/InputFormPanelMUI";
import AlertMUI from "./ui_MUI/AlertMUI";

export default function FormsPanel({ className, datos, onModifyDatos }) {
  const { formState, onInputChange } = useFormPanel(datos)
  const [indexButton, setIndexButton] = useState(0);
  // const [data, setData] = useState(datos);
  const [alert, setAlert] = useState([])
  const refsArray = [useRef(null), useRef(null)];
  const hForm = useRef(null);

  // useEffect(() => {
  //   setData(datos);
  // }, [datos]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formState.tajo1Tajo && (!formState.tajo1Hora || formState.tajo1Hora === 'Invalid Date')) {
      handleInvalid({ target: { id: 'tajoInput-0-Hora' } });
      return;
    }
    if (formState.tajo2Tajo && (!formState.tajo2Hora || formState.tajo2Hora === 'Invalid Date')) {
      handleInvalid({ target: { id: 'tajoInput-1-Hora' } });
      return;
    }

    // setAlert([{ alert: 'Success', textAlert: 'Datos Modificados Exitosamente!!!' }])
    
    const formNewDate = { ...formState, tajo1Fecha: formatDateSend(formState.tajo1Fecha) }
    // console.warn(formNewDate);
    onModifyDatos(formNewDate);
  };

  const handleInvalid = (e) => {
    setAlert([{ alert: 'Error', textAlert: 'Falta completar el formulario' }])
    if (e.target.form) {

      const form = e.target.form;
      const invalidFields = Array.from(form.elements).filter((input) => !input.validity.valid);
      if (invalidFields.length > 0) {
        if (e.target.id.split('-')[1]) {
          e.preventDefault();
          refsArray[e.target.id.split('-')[1]].current.click()
          invalidFields[0].parentNode.parentNode.classList.add('animate-aniInput');
          setTimeout(() => { invalidFields[0].parentNode.parentNode.classList.remove('animate-aniInput'); }, 2000);
        } else {
          hForm.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    } else {
      refsArray[e.target.id.split('-')[1]].current.click()
    }
  };

  const handleButtonClick = useCallback((index) => {
    setIndexButton(index);
  }, []);

  const formatDateToISO = (dateString) => {
    if (!dateString) return '';
    const isoFormat = /^\d{4}-\d{2}-\d{2}$/;
    if (isoFormat.test(dateString)) return dateString;
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const formatDateSend = (dateSend) => {
    const [year, month, day] = dateSend.split('-');
    return `${day}-${month}-${year}`
  }

  const handleAlerts = useCallback((newAlerts) => {
    setAlert(newAlerts);
  }, [setAlert]);

  return (
    <>
      <div ref={hForm} className={`h-full relative overflow-auto ${className}`}>
        <div className="sticky top-0 w-full bg-gradient-to-b from-[#F2911B] to-[#D91A1A] py-3 lg:py-4 rounded-t-xl lg:rounded-t-2xl z-[2]">
          <h1 className="text-left font-bold text-lg lg:text-2xl antialiased text-white px-4">
            CONFIGURACIÓN
          </h1>
        </div>
        <form className="flex flex-col gap-5 py-5 border border-r-[#E5E5E5] pt-2" onSubmit={handleSubmit} onInvalid={handleInvalid} >
          <div className="flex flex-col gap-y-4 px-5 font-Poppins text-sm">
            <p className="flex text-[#4D4D4D] gap-x-2 antialiased font-medium items-center">
              <TableCellsIcon
                className="h-5 w-5 shrink-0"
                aria-hidden="true"
              />
              TÍTULO DE PANEL:
            </p>
            <InputFormPanel
              id='titulo'
              name="titulo"
              value={formState.titulo}
              onChange={({ target: { name, value } }) => (onInputChange({ [name]: value }))}
              required
            ></InputFormPanel>
          </div>
          <p className="px-5 flex gap-2 text-[#4D4D4D] antialiased font-Poppins text-sm font-medium items-center">
            <CalendarDaysIcon
              className="h-6 w-6 shrink-0"
              aria-hidden="true"
            />
            FECHA:
          </p>
          <div className="flex gap-5 px-5 justify-between flex-wrap">
            <div className="w-full">
              <InputFormPanel
                id='fecha'
                name="tajo1Fecha"
                value={formatDateToISO(formState?.tajo1Fecha)}
                onChange={({ target: { name, value } }) => (onInputChange({ [name]: value }))}
                type="date"
                required
              ></InputFormPanel>
            </div>
          </div>
          <hr className="w-[100%] h-[1px] bg-[#E5E5E5]"></hr>
          <p className="px-5 flex gap-2 text-[#4D4D4D] antialiased font-Poppins text-sm font-medium items-center">
            <InformationCircleIcon
              className="h-6 w-6 shrink-0"
              aria-hidden="true"
            />
            TAJOS:
          </p>
          <div className="w-full flex flex-col gap-2 px-5 z-[1]">
            <CheckSelectInput
              label="TAJO 1"
              checked={formState.tajo1TajoCheck}
              nameItem="tajo1Tajo"
              valueItem={formState.tajo1Tajo}
              onInputChange={onInputChange}
            />
            <CheckSelectInput
              label="TAJO 2"
              checked={formState.tajo2TajoCheck}
              nameItem="tajo2Tajo"
              valueItem={formState.tajo2Tajo}
              onInputChange={onInputChange}
            />

          </div>
          {(formState.tajo1Tajo || formState.tajo2Tajo) &&
            <>

              <ButtonGroup
                handleButtonClick={handleButtonClick}
                indexActive={indexButton}
                indexSelect={formState.tajo1Tajo ? 0 : 1}
              >
                <span ref={refsArray[0]}> {formState.tajo1Tajo} </span>
                <span ref={refsArray[1]}> {formState.tajo2Tajo} </span>
              </ButtonGroup>

              <Carousel posicion={indexButton}>
                {[...Array(2)].map((_, i) => (
                  formState[`tajo${i + 1}Tajo`] && (
                    <div key={`tajoContainer-${i}`} className="flex flex-col gap-y-6 px-5 py-2">
                      {
                        [{ name: 'Banco', type: 'text' },
                        { name: 'Fase', type: 'text' },
                        { name: 'Proyecto', type: 'text' },
                        { name: 'Hora', type: 'time' }].map((item, _) => (
                          <InputFormPanelMUI
                            key={`tajoInput-${i}-${item.name}`}
                            id={`tajoInput-${i}-${item.name}`}
                            name={`tajo${i + 1}${item.name}`}
                            labelItem={`Ingrese el campo ${item.name}`}
                            defaultValue={formState[`tajo${i + 1}${item.name}`]}
                            onInputChange={onInputChange}
                            type={item.type}
                            required
                          />
                        ))}
                    </div>
                  )
                ))}
              </Carousel>
            </>
          }
          <div className="w-full text-center ">
            <ButtonKeyFrame
              id="Enviar"
              type="submit"
              name="Enviar"
              value="Enviar"
            >
              Guardar
            </ButtonKeyFrame>
          </div>
        </form>
      </div>
      {alert.alert !== '' &&
        <AlertMUI Alerts={alert} hanldeAlerts={handleAlerts} />
      }
    </>
  );
}
