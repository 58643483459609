import React from 'react'
import { IoMdMenu, IoMdReturnLeft } from "react-icons/io";
import LogoSvg from "../static/img/LogoIdeiperu.svg";
import { Link } from 'react-router-dom';

export default function Header({ handleDrawer, titulo }) {
    return (
        <header className="w-full fixed top-0 shadow-[0px_0px_10px_-4px_#000] z-30">
            <div className="w-full h-16 ps-4 pe-8 bg-[#f0f0f0] flex justify-between items-center max-[700px]:h-14">
                <button className="h-3/4 flex gap-3 items-center group z-10 max-[700px]:gap-2 max-[700px]:-ms-2" onClick={handleDrawer}>
                    <IoMdMenu className="min-w-[28.8px] text-[2.8rem] text-white p-[0.55rem] border-[1px] border-[#2e354d] bg-[#2e354d] rounded-md group-hover:text-[#2e354d] group-hover:bg-[#f0f0f0] max-[700px]:text-[1.8rem] max-[700px]:p-[0.2rem]" />
                    <img className="h-[2.25rem] max-[700px]:h-[1.8rem]" src={LogoSvg} alt="LogoIdeiperu" />
                </button>
                <div className='flex flex-1 justify-center text-center font-Poppins font-semibold text-[#2e354d] uppercase min-[810px]:text-[1.8rem] min-[730px]:text-[1.5rem] min-[699px]:text-[1.3rem] min-[530px]:text-[1rem] min-[400px]:text-[.8rem] max-[480px]:px-5 max-[500px]:hidden'>
                    {titulo}
                </div>
                {titulo &&
                    <Link className={`h-3/4 flex gap-3 items-center group z-10 max-[700px]:gap-2 max-[700px]:-ms-2 ${titulo === 'Paneles' && 'invisible'}`} to={'/Panel'}>
                        <IoMdReturnLeft className="min-w-[28.8px] text-[2.8rem] text-white p-[0.55rem] border-[1px] border-[#2e354d] bg-[#2e354d] rounded-md group-hover:text-[#2e354d] group-hover:bg-[#f0f0f0] max-[700px]:text-[1.8rem] max-[700px]:p-[0.2rem]" />
                    </Link>
                }
            </div>
        </header>
    )
}
