import { useState, useEffect } from "react";
import { MdCheckCircle, MdOutlineError, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { motion, AnimatePresence } from 'framer-motion';  // Importamos Framer Motion

const uppercaseRegex = /[A-Z]/;
const lowercaseRegex = /[a-z]/;
const numberRegex = /\d/;
const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;

export default function InputFormPassword({ children, id, type, verificationPassword = false, ...props }) {
    const [password, setPassword] = useState('');
    const [isValid, setIsValid] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSymbol: false,
    });
    const [isFocused, setIsFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    useEffect(() => {
        if (password !== '') setIsFocused(true)

        setIsValid({
            hasUpperCase: uppercaseRegex.test(password),
            hasLowerCase: lowercaseRegex.test(password),
            hasNumber: numberRegex.test(password),
            hasSymbol: symbolRegex.test(password),
        });
    }, [password]);

    const handlePaste = (event) => {
        event.preventDefault();
    }

    const handleChange = (e) => {
        setPassword(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    }

    const handleBlur = () => {
        if (!password) setIsFocused(false);
    }

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    return (
        <>
            <div className="flex flex-col gap-0">

                <label
                    htmlFor={id}
                    className="relative font-Poppins w-full block overflow-hidden rounded-md border border-gray-200 px-3 pt-5 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600  mb-1"
                >
                    <input
                        id={id}
                        onPaste={type === 'password' ? handlePaste : undefined}
                        {...props}
                        type={isPasswordVisible ? 'text' : 'password'}
                        value={password}
                        onChange={handleChange}
                        onFocus={() => { setIsFocused(true) }}  // Maneja el enfoque
                        onBlur={handleBlur}
                        className="peer font-Poppins text-base h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 "
                    />

                    <span
                        className="absolute font-Poppins start-3 top-4 -translate-y-1/2 text-xs text-[#969696] transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-4 peer-focus:text-xs"
                    >
                        {children}
                    </span>
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500"
                        aria-label={isPasswordVisible ? "Ocultar contraseña" : "Mostrar contraseña"}
                    >
                        {isPasswordVisible ? <MdVisibility /> : <MdVisibilityOff />}
                    </button>
                </label>
                {!verificationPassword &&
                    <AnimatePresence initial={false}>
                        {isFocused && (
                            <motion.div
                                initial={{ y: 50, opacity: 0, scale: 0.5 }} // Posición inicial y tamaño inicial
                                animate={{ y: 0, opacity: 1, scale: 1 }}   // Posición final y tamaño final
                                exit={{ y: 0, opacity: 0, scale: 0.5 }}   // Posición y tamaño al salir
                                transition={{
                                    opacity: { duration: 0.1 }, // Desvanecimiento
                                    scale: { duration: 0.1 }   // Cambio de tamaño
                                }}
                            >
                                <ValidationMessage
                                    isValid={isValid.hasUpperCase}
                                    message="Debe contener al menos una letra mayúscula"
                                />
                                <ValidationMessage
                                    isValid={isValid.hasLowerCase}
                                    message="Debe contener al menos una letra minúscula"
                                />
                                <ValidationMessage
                                    isValid={isValid.hasNumber}
                                    message="Debe contener al menos un número"
                                />
                                <ValidationMessage
                                    isValid={isValid.hasSymbol}
                                    message="Debe contener al menos un símbolo (!@#$%^&*)"
                                />
                            </motion.div>
                        )}

                    </AnimatePresence>
                }
            </div>
        </>
    )
}

function ValidationMessage({ isValid, message }) {
    const Icon = isValid ? MdCheckCircle : MdOutlineError;
    const iconColor = isValid ? "text-[#0db801]" : "text-[#b80d01]";
    return (
        <div className="ms-2 flex items-center gap-1 text-[.7rem]">
            <Icon className={iconColor} />
            <p>{message}</p>
        </div>
    );
}