import { useState } from "react"

const useForm = (initialForm = {}) => {
    const [formState, setformState] = useState(initialForm)

    const onInputChange = ({ target }) => {
        const { name, value, type, checked } = target
        if(type === 'checkbox'){
            setformState({ ...formState, [name]: checked })
            return;
        }
        setformState({ ...formState, [name]: value })
    }
    return {
        formState,
        onInputChange
    };
}

export default useForm