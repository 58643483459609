import { useEffect, useState } from 'react'

const clearData = (dataInitial) => {
    const dataReturned = {
        ...dataInitial,
        ...(dataInitial.tajo1Tajo === '--' || dataInitial.tajo1Tajo === 'SIN' ? { tajo1Tajo: '', tajo1TajoCheck: false } : {}),
        ...(dataInitial.tajo2Tajo === '--' || dataInitial.tajo2Tajo === 'VOLADURA' ? { tajo2Tajo: '', tajo2TajoCheck: false } : {}),
    };
    return dataReturned;
}

const useFormPanel = (initialForm = {}) => {
    const [formState, setformState] = useState(initialForm)

    useEffect(() => { setformState(clearData(initialForm)); }, [initialForm]);

    const onInputChange = (target) => {
        setformState((prev) => ({ ...prev, ...target }))
    }
    return {
        formState,
        onInputChange
    };
}

export default useFormPanel