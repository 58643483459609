export const formatRegisterData = (dataWeb, user, namePanel, fechaRegistro) => {
    try {
        const trimmedData = Object.fromEntries(
            Object.entries(dataWeb).map(([key, value]) => [key, typeof value === 'string' ? value === ' ' ? value : value.trim() : value])
        );
        const camposCompletos = {
            user,
            namePanel,
            fechaRegistro,

            campoBanco: trimmedData.campoBanco,
            campoFase: trimmedData.campoFase,
            campoFecha: trimmedData.campoFecha,
            campoHora: trimmedData.campoHora,
            campoProyecto: trimmedData.campoProyecto,
            campoTajo: trimmedData.campoTajo,

            tajo1Banco: trimmedData.tajo1Tajo ? trimmedData.tajo1Banco : '--',
            tajo1Fase: trimmedData.tajo1Tajo ? trimmedData.tajo1Fase : '--',
            tajo1Fecha: trimmedData.tajo1Fecha,
            tajo1Hora: trimmedData.tajo1Tajo ? trimmedData.tajo1Hora : '--:--',
            tajo1Proyecto: trimmedData.tajo1Tajo ? trimmedData.tajo1Proyecto : '--',
            tajo1Tajo: trimmedData.tajo1Tajo ? trimmedData.tajo1Tajo : (trimmedData.tajo1Tajo || trimmedData.tajo2Tajo ? '--' : 'SIN'),

            tajo2Banco: trimmedData.tajo2Tajo ? trimmedData.tajo2Banco : '--',
            tajo2Fase: trimmedData.tajo2Tajo ? trimmedData.tajo2Fase : '--',
            tajo2Hora: trimmedData.tajo2Tajo ? trimmedData.tajo2Hora : '--:--',
            tajo2Proyecto: trimmedData.tajo2Tajo ? trimmedData.tajo2Proyecto : '--',
            tajo2Tajo: trimmedData.tajo2Tajo ? trimmedData.tajo2Tajo : (trimmedData.tajo2Tajo || trimmedData.tajo1Tajo ? '--' : 'VOLADURA'),
            titulo: trimmedData.titulo,

        }
        return camposCompletos
    } catch (error) {
        console.error('Error en formatData:', error);
        return {};
    }
}
