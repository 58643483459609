import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from '../firebase';
import { validatePassword } from "../components/helpers/validatePassword";
import CryptoJS from 'crypto-js';
import { child, get, ref, set, push, update, remove } from 'firebase/database'
import { db } from '../firebase'
import { v4 as uuidv4 } from 'uuid';


export const authContext = createContext();

export const useAuth = () => {
    const context = useContext(authContext);
    if (!context) throw new Error('No hay proveedor de autenticación');
    return context;
}

export function AuthProvider({ children }) {
    const initialError = {
        error: 'Ingrese a su cuenta',
        stateText: 'normal',
    }
    const [user, setUser] = useState(null)
    const [userCurrent, setUserCurrent] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(initialError)

    const getUsers = async () => {
        try {
            const snapshot = await get(child(ref(db), `PV_${user.Mine}/Users`));
            if (snapshot.exists()) {
                const data = Object.values(snapshot.val());
                return data;
            } else {
                console.log("No data available");
                return false;
            }
        } catch (errors) {
            console.error("salio el siguiente error: ", errors)
            return false;
        }
    }

    const deleteUser = async (uuidUser) => {
        try {
            const snapshot = await get(child(ref(db), `PV_${user.Mine}/Users`));
            if (snapshot.exists()) {
                const entries = Object.entries(snapshot.val());
                const filteredItem = entries.find(([key, value]) => value.ID === uuidUser);

                if (filteredItem) {
                    const [key, value] = filteredItem;
                    await remove(ref(db, `PV_${user.Mine}/Users/${key}`));
                    await remove(ref(db, `PV_${user.Mine}/Users/${value.username.split('.com')[0].toLowerCase()}`));
                    await remove(ref(db, `AllowedUsers/${value.username.split('.com')[0].toLowerCase()}`));
                    if (value.registerAt !== 'No registrado') {
                        return [{ alert: 'Error', textAlert: `Conctarse con soporte para eliminar completamente el usuario ${value.username}` }]
                    } else {
                        return [{ alert: 'Succes', textAlert: `Se eliminó el usuario ${value.username} correctamente` }]
                    }
                } else {
                    return [{ alert: 'Error', textAlert: `No se encontró un usuario con ese ID.` }]
                }
            } else {
                return [{ alert: 'Error', textAlert: `No se pudo conectar con la base de datos` }]
            }
        } catch (errors) {
            console.error("salio el siguiente error: ", errors)
            return [{ alert: 'Error', textAlert: `Comunicarse con soporte tecnico, falla del sistema` }]
        }
    }

    const createAllowedUser = async (userInfo) => {
        try {
            const newUserEmail = userInfo.username.split('.com')[0].toLowerCase();
            const newUserAllowed = {
                Mine: user.Mine,
                SuperUser: JSON.parse(userInfo.superuser),
            };

            const snapshot = await get(child(ref(db), `AllowedUsers/${newUserEmail}`));
            if (snapshot.exists()) {
                return [{ alert: 'Error', textAlert: `El usuario ${userInfo.username} ya existe en el sistema` }]
            } else {
                const nowdate = new Date().toISOString();
                const newUserRegister = {
                    ID: uuidv4(),
                    username: userInfo.username,
                    superuser: JSON.parse(userInfo.superuser),
                    createdAt: nowdate,
                    registerAt: 'No registrado',
                    updatedAt: '-----',
                };
                await set(ref(db, `AllowedUsers/${newUserEmail}`), newUserAllowed);
                await set(ref(db, `PV_${user.Mine}/Users/${newUserEmail}`), newUserRegister);
                return [{ alert: 'Succes', textAlert: `Usuario ${userInfo.username} creado exitosamente!!` }]
            }
        } catch (errors) {
            console.error(errors);
            return [{ alert: 'Error', textAlert: errors }]
        }
    }

    const signup = async (userInfo) => {
        setLoading(true)
        try {
            const { isValid, error: validationError } = validatePassword(userInfo.Password, userInfo.VerificationPassword);

            if (!isValid) {
                setError({
                    error: validationError,
                    stateText: 'error',
                });
                debugger;
                return false;
            }
            const username = userInfo.Email.split('.com')[0].toLowerCase();

            const snapshot = await get(child(ref(db), `AllowedUsers/${username}`));
            if (snapshot.exists()) {
                const userCredential = await createUserWithEmailAndPassword(auth, userInfo.Email, userInfo.Password);
                const snapshotUser = await get(child(ref(db), `PV_${snapshot.val().Mine}/Users/${username}`));
                if (snapshotUser.exists()) {
                    const updatedAt = new Date(Number(userCredential.user.reloadUserInfo.lastLoginAt)).toISOString();
                    const newUser =
                    {
                        ...snapshotUser.val(),
                        updatedAt,
                        registerAt: updatedAt,
                    }
                    await set(ref(db, `PV_${snapshot.val().Mine}/Users/${userCredential.user.uid}`), newUser);
                    await remove(ref(db, `PV_${snapshot.val().Mine}/Users/${username}`));
                    // await remove(ref(db, `AllowedUsers/${username}`));
                    // const newUserRef = push(ref(db, `PV_${snapshot.val().Mine}/Users`)); // Crea una nueva clave única
                    // await set(newUserRef, newUser);
                    // debugger;
                    const user = userCredential.user;
                    return user;
                } else {
                    return false;
                }
            } else {
                setError({
                    error: 'El correo no tiene los permisos de administrador o ya se encuentra registrado',
                    stateText: 'error',
                });
                // debugger;
                return false;
            }
        } catch (errors) {
            if (errors.code === 'auth/weak-password') {
                setError({
                    error: 'La contraseña debe tener mas de 6 caracteres',
                    stateText: 'error',
                });
            } else if (errors.code === 'auth/email-already-in-use') {
                setError({
                    error: 'El correo ya se encuentra registrado',
                    stateText: 'error',
                });

            } else {
                setError({
                    error: errors.code,
                    stateText: 'error',
                });
            }
            console.error(errors);
            // debugger;
            return false;
        } finally {
            setLoading(false);
        }
    }

    const login = async (userInfo) => {
        setLoading(true)
        try {
            const respuesta = await signInWithEmailAndPassword(auth, userInfo.Email, userInfo.Password);
            createTokenWeb(userInfo.stayLoggedIn)
            const dataUser = await getDataUser(respuesta.user.email)
            setUser(dataUser)
            if (!dataUser.Type || dataUser.Type !== 'admin') {
                const updatedAt = new Date(Number(respuesta.user.reloadUserInfo.lastLoginAt)).toISOString();
                await update(ref(db, `PV_${dataUser.Mine}/Users/${respuesta.user.uid}`), { updatedAt });
            }
            return respuesta;
        } catch (errors) {
            console.error(errors)
            setError({
                error: 'Datos Incorrectos, vuelva a intentar',
                stateText: 'error',
            });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const getDataUser = async (userLog) => {
        const username = userLog.split('.com')[0];
        try {
            const snapshot = await get(child(ref(db), `AllowedUsers/${username}`));
            if (snapshot.exists()) {
                return { ...snapshot.val(), email: userLog };
            } else {
                console.warn('No data available');
                return null;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    }

    const logout = async () => {
        setLoading(true)
        try {
            await signOut(auth)
            setUser(null)
            setLoading(true)
            setError(initialError)
            // navigate('/')
            // window.location.reload()
        } catch (errors) {
            setError({
                error: errors.message,
                stateText: 'error',
            });
            console.error("Error LogOUT");
            console.error(errors.message);
        } finally {
            setLoading(false);
        }
    }

    const updateError = useCallback((newError) => setError(newError), []);

    const createTokenWeb = (keepSessionActive = false) => {
        let timeString = ''
        if (keepSessionActive) {
            timeString = (Date.now() + 7 * 24 * 60 * 60 * 1000).toString()
        } else {
            timeString = (Date.now() + 1 * 60 * 1000).toString()
        }
        // const encrypted = CryptoJS.AES.encrypt(timeString, process.env.REACT_APP_ENDPOINT_SECRET_KEY).toString();
        const encrypted = CryptoJS.AES.encrypt(timeString, 'QZY6~w^L";').toString();
        localStorage.setItem('sessionExpiry', encrypted);
    }

    const checkTokenExpiry = useCallback(() => {
        const encryptedSessionExpiry = localStorage.getItem('sessionExpiry');

        try {
            // const bytes = CryptoJS.AES.decrypt(encryptedSessionExpiry, process.env.REACT_APP_ENDPOINT_SECRET_KEY);
            const bytes = CryptoJS.AES.decrypt(encryptedSessionExpiry, 'QZY6~w^L";');
            const sessionExpiryStr = bytes.toString(CryptoJS.enc.Utf8);
            const sessionExpiry = parseInt(sessionExpiryStr, 10);
            if (isNaN(sessionExpiry)) {
                throw new Error('El valor desencriptado no es un número válido.');
            }

            if (Date.now() > sessionExpiry) {
                localStorage.removeItem('sessionExpiry');
                setError({
                    error: 'Se termino el tiempo se sesion',
                    stateText: 'error',
                });
                logout();
            }
        } catch (error) {
            logout();
        }
    }, []);

    useEffect(() => {
        const checkUser = () => {
            const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
                if (currentUser) {
                    const dataUser = await getDataUser(currentUser.email);
                    setUserCurrent(currentUser);
                    setUser(dataUser);
                    setLoading(false);
                    checkTokenExpiry();
                    const intervalId = setInterval(checkTokenExpiry, 60000);
                    return () => { clearInterval(intervalId) };
                } else {
                    setUser(null);
                    setLoading(false);
                }
            });
            return () => unsubscribe();
        };

        checkUser();
    }, [checkTokenExpiry]);


    return <authContext.Provider value={{ signup, login, logout, updateError, createAllowedUser, getUsers, deleteUser, user, loading, error }}>{children}</authContext.Provider>;
}

