import { Link, useNavigate } from "react-router-dom";
import { LabelStatus, InputForm, ButtonForm } from '../components/ui'
import { useAuth } from '../context/authContext'
import useForm from "../components/hooks/useForm.js";
import LogoSvg from '../static/img/LogoIdeiperu.svg';
import InputFormPassword from '../components/ui/InputFormPassword.jsx';
import PageWait from '../components/shared/PageWait.jsx';

const initialForm = {
    Email: '',
    Password: '',
    VerificationPassword: '',
}

const Register = () => {
    const { formState, onInputChange } = useForm(initialForm)
    const { signup, updateError, error, loading } = useAuth()
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await signup(formState);
        if (response) {
            updateError({ error: 'Usuario registrado correctamente', stateText: 'success' })
            navigate("/")
        }
    }

    return (
        <>
            {loading ? (<PageWait />) : (
        <form
            className="bg-white flex flex-col text-center px-[3.6458%] py-[2.8%] rounded-2xl w-[90%] h-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] gap-8 sm:w-[80%] sm:px-8 sm:py-10 md:w-[60%] lg:w-[33%] xl:px-[3.6458%] 2xl:w-[27.34375%] pt-10"
            onSubmit={handleSubmit}
        >
            <img className="w-[82.78%] m-auto" src={LogoSvg} alt="LogoIdeiperu" />

            <LabelStatus state={error.stateText} >
                {error.error && <p>{error.error}</p>}
            </LabelStatus>

            <div className="flex flex-col text-center w-full  gap-[15px] ">
                <InputForm
                    id="Email"
                    name="Email"
                    type="email"
                    placeholder=""
                    onChange={onInputChange}
                    required
                >Ingrese su nuevo Correo</InputForm>
                <InputFormPassword
                    id="Password"
                    name="Password"
                    type="password"
                    placeholder=""
                    onChange={onInputChange}
                    required
                >Ingrese su nueva Contraseña</InputFormPassword>
                <InputFormPassword
                    id="VerificationPassword"
                    name="VerificationPassword"
                    type="password"
                    placeholder=""
                    onChange={onInputChange}
                    required
                    verificationPassword
                >Repita su nueva Contraseña</InputFormPassword>
            </div>

            <div className="flex items-center justify-around pb-10">
                <ButtonForm tipo="Form">
                    REGISTRAR CUENTA
                </ButtonForm>
            </div>

            <div className="absolute bottom-5 right-5 flex justify-end items-center gap-4">
                <Link
                    className="text-[#969696] font-Poppins text-md font-bold leading-5 underline underline-offset-[5px] cursor-pointer hover:text-[#B80D01] transition duration-200 focus:text-[#B80D01]"
                    to="/"
                    onClick={() => updateError({ error: 'Ingrese a su cuenta', stateText: 'normal' })}
                >Iniciar Sesión</Link>
            </div>
        </form>
            )

            }
        </>
    )
}

export default Register