const FIELD_SEPARATOR = '~';

const getDefaultFormattedData = () => ({
    campoFecha: '',
    campoTajo: '',
    campoBanco: '',
    campoFase: '',
    campoProyecto: '',
    campoHora: '',
    tajo1Fecha: '',
    tajo1Tajo: '',
    tajo1Banco: '',
    tajo1Fase: '',
    tajo1Proyecto: '',
    tajo1Hora: '',
    tajo2Tajo: '',
    tajo2Banco: '',
    tajo2Fase: '',
    tajo2Proyecto: '',
    tajo2Hora: '',
    titulo: '',
});

const getResults = (dataDB) => {
    const results = {};
    dataDB.forEach((data,index) => {
        results[index] = data.Text;
    });
    return results;
}

const getFormattedData = (results) => ({
    campoFecha: results[0]?.split(FIELD_SEPARATOR)[1].trim(),
    campoTajo: results[0]?.split(FIELD_SEPARATOR)[2].trim(),
    campoBanco: results[0]?.split(FIELD_SEPARATOR)[3].trim(),
    campoFase: results[0]?.split(FIELD_SEPARATOR)[4].trim(),
    campoProyecto: results[0]?.split(FIELD_SEPARATOR)[5].trim(),
    campoHora: results[0]?.split(FIELD_SEPARATOR)[6].trim(),
    tajo1Hora: results[1].trim(),
    tajo1Proyecto: results[2].trim(),
    tajo1Fase: results[3].trim(),
    tajo1Banco: results[4].trim(),
    tajo1Tajo: results[5].trim(),
    tajo1TajoCheck: !!results[5].trim(),
    tajo1Fecha: results[6].trim(),
    tajo2Hora: results[7].trim(),
    tajo2Proyecto: results[8].trim(),
    tajo2Fase: results[9].trim(),
    tajo2Banco: results[10].trim(),
    tajo2Tajo: results[11].trim(),
    tajo2TajoCheck: !!results[11].trim(),
    titulo: results[12].trim(),
});


export const formatWebData = (dataDB = {}) => {
    try {
        if (Object.entries(dataDB).length === 0) {
            return getDefaultFormattedData();
        } else {
            const results = getResults(dataDB);
            return getFormattedData(results);
        }
    } catch (error) {
        console.error('Error en formatData:', error);
        return getDefaultFormattedData();
    }
}