import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function PageWait() {
    return (
        <>
            <div className="w-full h-screen bg-[#CACACA]">
                <div className="w-full h-full absolute flex items-center z-10 justify-center bg-[#000000d6]">
                    <Box className="relative flex items-center justify-center">
                        <CircularProgress
                            className='absolute '
                            size={400}
                            thickness={1}
                            sx={{ color: '#B80D01' }}
                        />
                        <CircularProgress
                            className='absolute '
                            size={350}
                            thickness={1}
                            sx={{ color: '#B80D01' }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Typography variant="h4" component="div" className='text-white !font-Poppins !text-3xl !animate-ping'>
                                Cargando...
                            </Typography>
                        </Box>
                    </Box>
                </div>
                <div className="w-full h-[82.51%] bg-[#B80D01]" style={{ clipPath: 'polygon(0 0, 100% 0, 100% 34.60%, 0 100%)' }}></div>

                <div className="absolute bottom-2 right-2 text-sm text-[#888888] max-[650px]:text-[7px] text-right">
                    By signing in, you agree to IDEI's Conditions of Use & Sale. See our Privacy, Cookies, and Interest-Based Ads Notices.
                </div>
            </div>
        </>
    );
}

export default PageWait;
