import { useEffect, useState } from "react";

export  default function InputFormPanel({children, id, className, secondClass, value, required, ...props}) { 
  const [classInput, setClassInput] = useState('')
  const [placeholderText, setPlaceholderText] = useState('')
  
  useEffect(() => { setClassInput(value ? '' : 'bg-red-200'); }, [value]);

  // useEffect(() => {
  //   if (placeholderText === 'Este campo es requerido') {
  //     setClassInput('')
  //     setPlaceholderText('')
  //   }
  // }, [required]);

  const handleBlur = ({target: {name, value}}) => {
    if ([required] && value.trim() === '') {
      setClassInput('bg-red-200')
      setPlaceholderText('Este campo es requerido')
    }else{
      setClassInput('')
      setPlaceholderText('')
    }
  }

  return (
    <>
      <div>
        <label htmlFor={id}>
          {children}
        </label>
        <input
          id={id}
          value={value}
          onBlur={handleBlur}
          required={required}
          placeholder={placeholderText}
          className={`border w-full bg-[#F9F9F9] text-[#4D4D4D] antialiased font-Poppins text-xs font-thin lg:text-sm rounded-lg p-2.5 focus:border-[#6D6F6E] focus:outline-none disabled:bg-gray-300 disabled:text-gray-300 ${classInput}`}
          {...props}
        />
      </div>
    </>
  );
}