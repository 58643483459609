import React from 'react'
import Tooltip from '@mui/material/Tooltip';

export const TooltipHeader = ({ children, ...props }) => {
    return (
        <Tooltip
            arrow
            placement="right"
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0], // Ajusta el segundo valor para cambiar la distancia vertical
                        },
                    },
                ],
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#fff',
                        color: '#2e354d',
                        fontSize: '16px',
                        fontWeight: '600',
                        boxShadow: '2px 2px 2px 0',
                    },
                },
                arrow: {
                    sx: {
                        color: '#ffff',
                    },
                },
            }}
            {...props}
        >
            {children}
        </Tooltip>
    )
}
