import React, { useEffect, useState } from 'react';
import { CheckBox } from '.';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CheckSelectInput({ nameItem, label, valueItem, checked, onInputChange }) {
    const [isChecked, setIsChecked] = useState(checked);
    const [textInput, setTextInput] = useState(valueItem);

    useEffect(() => { setIsChecked(checked); }, [checked]);

    useEffect(() => { setTextInput(valueItem); }, [valueItem]);

    const handleChange = ({ target: { name, checked, textContent, value } }) => {
        if (name?.includes('checkInput')) {
            setIsChecked(checked);
            onInputChange(checked ? { [nameItem]: textInput } : { [nameItem]: '' });
        } else {
            if (textContent) {
                setTextInput(textContent);
                onInputChange({ [nameItem]: textContent });
            } else {
                setTextInput(value ? value : '')
                onInputChange({ [nameItem]: (value ? value : '') });
            }
        }
    };

    return (
        <div className="grid grid-cols-2">
            <span className="place-self-center justify-self-start font-Poppins text-sm font-thin">
                <CheckBox
                    className={`text-[#4D4D4D] tracking-widest ${label === "PRIMARIO" || label === "PRECORTE" ? "pr-7" : ""}`}
                    checked={isChecked}
                    onChange={handleChange}
                    name='checkInput'
                >
                    {label}
                </CheckBox>
            </span>

            <Autocomplete
                id={nameItem}
                freeSolo
                disabled={!isChecked}
                inputValue={isChecked ? textInput : ''}
                onInputChange={handleChange}
                options={['PAMPACANCHA', 'CONSTANCIA']}
                renderInput={(params) => <TextField {...params} label={`${isChecked && textInput   ? '' : 'Seleccionar'} Tajo`} />}
            />
        </div>
    );
}
