
export const validatePassword = (password, confirmPassword) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password !== confirmPassword) {
        return {
            isValid: false,
            error: 'Las contraseñas son diferentes',
        };
    }

    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSymbol) {
        return {
            isValid: false,
            error: 'La contraseña no cumple con los requisitos de seguridad',
        };
    }

    return { isValid: true };
};