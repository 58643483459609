import React from 'react'

export default function TableOfTable({ data }) {
    return (
        <div className='w-full h-fit flex justify-center items-center'>

            <table className="table-fixed border-[1px] text-center m-5 p-5 w-[80%] ">
                <thead className="border-[1px]">
                    <tr className='bg-[#2e354d15]'>
                        <th colSpan={3} className="border-[1px]">{data.namePanel}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='bg-[#2e354d05]'>
                        <td className="border-[1px] font-Poppins w-1/3 font-bold">TITULO:</td>
                        <td colSpan={2} className="border-[1px] font-Poppins w-1/3">{data.titulo}</td>
                    </tr>
                    <tr className='bg-[#2e354d15]'>
                        <td className="border-[1px] font-Poppins w-1/3 font-bold">{data.campoFecha}</td>
                        <td colSpan={2} className="border-[1px] font-Poppins w-1/3">{data.tajo1Fecha}</td>
                    </tr>
                    <tr className='bg-[#2e354d05]'>
                        <td className="border-[1px] font-Poppins w-1/3 font-bold">{data.campoTajo}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo1Tajo}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo2Tajo}</td>
                    </tr>
                    <tr className='bg-[#2e354d15]'>
                        <td className="border-[1px] font-Poppins w-1/3 font-bold">{data.campoBanco}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo1Banco}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo2Banco}</td>
                    </tr>
                    <tr className='bg-[#2e354d05]'>
                        <td className="border-[1px] font-Poppins w-1/3 font-bold">{data.campoFase}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo1Fase}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo2Fase}</td>
                    </tr>
                    <tr className='bg-[#2e354d15]'>
                        <td className="border-[1px] font-Poppins w-1/3 font-bold">{data.campoProyecto}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo1Proyecto}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo2Proyecto}</td>
                    </tr>
                    <tr className='bg-[#2e354d05]'>
                        <td className="border-[1px] font-Poppins w-1/3 font-bold">{data.campoHora}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo1Hora}</td>
                        <td className="border-[1px] font-Poppins w-1/3">{data.tajo2Hora}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
