export default function ButtonKeyFrame({ children, ...props }) {
    return (
      <button
        className="w-[50%] font-Poppins font-medium text-sm h-full py-3 relative inline-block bg-[#2E354D] text-[#ffffff] text-[20px] hover:bg-[#ffffff] hover:text-[#2E354D] hover:rounded-2 duration-500 no-underline uppercase overflow-hidden border-none bg-none tracking-[8px]"
        {...props}
      >
        <span className="absolute block top-0 left-[-100%] w-full h-[2px] rotate-[0deg] bg-gradient-to-r from-transparent to-[#475379] animate-span1"></span>
        <span
          className="absolute block top-[-100%] right-0 w-[2px] h-full rotate-[180deg] bg-gradient-to-r from-transparent to-[#2E354D] animate-span2 "
          style={{ animationDelay: "0.25s" }}
        ></span>
        <span
          className="absolute block bottom-0 right-[-100%] w-full h-[2px] rotate-[180deg] bg-gradient-to-r from-transparent to-[#475379] animate-span3 "
          style={{ animationDelay: "0.5s" }}
        ></span>
        <span
          className="absolute block left-0 bottom-[-100%] w-[2px] h-full rotate-[0deg] bg-gradient-to-r from-transparent to-[#475379] animate-span4 "
          style={{ animationDelay: "0.75s" }}
        ></span>
        {children}
      </button>
    );
  }
  