import { useAuth } from "../context/authContext"
import { MenuContext } from "../context/menuContext";
import TableCrudsWithProviders from '../components/TableCrud'
import Header from '../layouts/Header'
import { useFetchData } from '../components/hooks/useFetchData'
import BackgroundStyle from "../components/ui/BackgroundStylePage"
import { useContext, useState } from "react"
import TituloPanel from "../components/TituloPanel"

const Cruds = () => {
  const { data, isLoading } = useFetchData();
  const { logout, loading } = useAuth();
  const [heightHeader, setHeightHeader] = useState(0);
  const getHeight = (height) => {
    setHeightHeader(height);
  };

  if (loading) {
    return (
      <>
        <BackgroundStyle>
          <div class="flex items-center justify-center min-h-screen">
            <h1 class="text-center text-2xl font-bold">CARGANDO ...</h1>
          </div>
        </BackgroundStyle>
      </>
    );
  }

  return (
    <>
      {!isLoading && !loading ? (
        <>
          <div className="flex flex-row bg-[#F9F9F9]">
            {/* <div className="flex-1 2xl:ml-72 bg-[#F9F9F9] min-h-screen lg:max-h-screen"> */}
            {/* <div className="grid mt-20 mb-5 lg:mr-10 lg:mt-28 lg:pl-8 bg-yellow gap-4 lg:gap-8"> */}
            <div className="w-full h-full p-5">
              <TableCrudsWithProviders />
              {/* </div> */}
            </div>
          </div>
        </>
      ) : (
        <BackgroundStyle>
          <div className="flex items-center justify-center min-h-screen">
            <h1 className="text-center text-lg px-5 lg:px-0 lg:text-2xl font-semibold font-Poppins text-[#4D4D4D]">
              CARGANDO INFORMACIÓN DE ACTIVIDADES ...
            </h1>
          </div>
        </BackgroundStyle>
      )}
    </>
  );

}

export default Cruds