export const formatCreateP10 = (data) => {
    if (!data) {
        return [[
            { color: 'green', p10: "1x3", right: "73.95", top: "35.84"},
            { color: 'green', p10: "1x3", right: "73.95", top: "45.86"},
            { color: 'green', p10: "1x3", right: "73.95", top: "55.89"},
            { color: 'green', p10: "1x3", right: "73.95", top: "65.91"},
            { color: 'green', p10: "1x3", right: "73.95", top: "75.94"},
            { color: 'green', p10: "1x3", right: "73.95", top: "85.96"},

            { color: 'red', p10: "1x4", right: "38.48", top: "35.84"},
            { color: 'red', p10: "1x4", right: "38.48", top: "45.86"},
            { color: 'red', p10: "1x4", right: "38.48", top: "55.89"},
            { color: 'red', p10: "1x4", right: "38.48", top: "65.91"},
            { color: 'red', p10: "1x4", right: "38.48", top: "75.94"},
            { color: 'red', p10: "1x4", right: "38.48", top: "85.96"},

            { color: 'red', p10: "1x4", right: "3", top: "45.86"},
            { color: 'red', p10: "1x4", right: "3", top: "55.89"},
            { color: 'red', p10: "1x4", right: "3", top: "65.91"},
            { color: 'red', p10: "1x4", right: "3", top: "75.94"},
            { color: 'red', p10: "1x4", right: "3", top: "85.96"},
        ],{state:false}];
    }
    return [[
        { color: 'green', p10: "1x3", right: "73.95", top: "35.84", text: data.campoFecha },
        { color: 'green', p10: "1x3", right: "73.95", top: "45.86", text: data.campoTajo },
        { color: 'green', p10: "1x3", right: "73.95", top: "55.89", text: data.campoBanco },
        { color: 'green', p10: "1x3", right: "73.95", top: "65.91", text: data.campoFase },
        { color: 'green', p10: "1x3", right: "73.95", top: "75.94", text: data.campoProyecto },
        { color: 'green', p10: "1x3", right: "73.95", top: "85.96", text: data.campoHora },

        { color: 'red', p10: "1x4", right: "38.48", top: "35.84", text: data.tajo1Fecha },
        { color: 'red', p10: "1x4", right: "38.48", top: "45.86", text: data.tajo1Tajo },
        { color: 'red', p10: "1x4", right: "38.48", top: "55.89", text: data.tajo1Banco },
        { color: 'red', p10: "1x4", right: "38.48", top: "65.91", text: data.tajo1Fase },
        { color: 'red', p10: "1x4", right: "38.48", top: "75.94", text: data.tajo1Proyecto },
        { color: 'red', p10: "1x4", right: "38.48", top: "85.96", text: data.tajo1Hora },

        { color: 'red', p10: "1x4", right: "3", top: "45.86", text: data.tajo2Tajo },
        { color: 'red', p10: "1x4", right: "3", top: "55.89", text: data.tajo2Banco },
        { color: 'red', p10: "1x4", right: "3", top: "65.91", text: data.tajo2Fase },
        { color: 'red', p10: "1x4", right: "3", top: "75.94", text: data.tajo2Proyecto },
        { color: 'red', p10: "1x4", right: "3", top: "85.96", text: data.tajo2Hora },
    ],{state:true}];
}
