import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./authContext";
import { db } from "../firebase";
import { onValue, ref, set, get, child } from 'firebase/database'
import { formatWebData } from "../components/helpers/formatWebData";
import { formatRealtimeData } from "../components/helpers/formatRealtimeData";
import { formatRegisterData } from "../components/helpers/formatRegisterData";

export const dataContext = createContext();

export const useData = () => {
    const context = useContext(dataContext);
    if (!context) throw new Error('No hay proveedor de autenticación');
    return context;
}

export function DataProvider({ children }) {
    const [dataFirebase, setDataFirebase] = useState(null);
    const [dataUI, setDataUI] = useState(null);
    const [loadData, setLoadData] = useState(true);
    const [cantPanels, setCantPanels] = useState({});
    const [dataSelect, setDataSelect] = useState(null);
    const { user } = useAuth();

    const updateData = async (dataUpdate, dataCheckBoxs) => {
        try {
            const dataRealtime = formatRealtimeData(dataUpdate)
            for (const [keyDataCheckBoxs, valueDataCheckBoxs] of Object.entries(dataCheckBoxs)) {
                if (valueDataCheckBoxs) {
                    for (const [key, value] of Object.entries(dataRealtime)) {
                        await set(ref(db, `PV_${user.Mine}/Blasting_Panel/Address/${keyDataCheckBoxs}/Fields/${key}/Text`), value);
                    }
                    await updateRegister(dataUpdate, keyDataCheckBoxs)
                }
            }
            return true;
        } catch (errors) {
            return null;
        }
    }

    const updateRegister = async (dataUpdate, indexData) => {
        try {
            const nowDate = new Date().toISOString();
            const namePanel = dataFirebase[indexData]['dataInfo']['Name']
            const newRegister = formatRegisterData(dataUpdate, user.email, namePanel, nowDate)
            await set(ref(db, `PV_${user.Mine}/Records/${nowDate.slice(0, 19)}/panel_${indexData}`), newRegister);
            return true;
        } catch (errors) {
            return null;
        }
    }

    const getRegister = async (dateRange) => {
        try {
            const snapshot = await get(child(ref(db), `PV_${user.Mine}/Records`));
            if (snapshot.exists()) {
                const data = Object.entries(snapshot.val()).flatMap(([fechaRegistro, panels]) => {
                    return Object.entries(panels).map(([panel, details]) => ({ ...details}));
                });
                return data;
            } else {
                console.log("No data available");
                return false;
            }
        } catch (errors) {
            console.error("salio el siguiente error: ", errors)
            return false;
        }
    }

    useEffect(() => {
        setLoadData(true);
        if (!user) {
            setDataFirebase(null);
            setLoadData(true);
            setCantPanels(null);
            setDataUI(null);
            return;
        }
        const dataRef = ref(db, `PV_${user.Mine}/Blasting_Panel/UI_PANEL_SETTINGS`);
        onValue(dataRef, (snapshot) => {
            if (snapshot.exists()) {
                setDataUI(snapshot.val());
                setCantPanels({ val: snapshot.val().MAC_LIST, mine: user.Mine })
            } else {
                setDataUI(null);
                setCantPanels(null)
                setLoadData(false);
            }
        }, (error) => {
            console.error('Error fetching data:', error);
            setDataUI(null);
            setCantPanels(null)
            setLoadData(false);
        });

    }, [user])

    useEffect(() => {
        setLoadData(true);
        const listeners = [];
        if (!cantPanels || !cantPanels.val) {
            return;
        }

        const fetchedData = {};

        const fetchData = (item, key) => {
            const dataRef = ref(db, `PV_${cantPanels.mine}/Blasting_Panel/Address/${item}/${key}`);
            return new Promise((resolve) => {
                let stateListener = false;
                onValue(dataRef, (snapshot) => {
                    if (snapshot.exists()) {
                        if (stateListener) {
                            setDataFirebase((prev) => ({
                                ...prev,
                                [item]: {
                                    ...prev[item],
                                    [`data${key}`]: formatWebData(snapshot.val())
                                }
                            }));
                        }
                        resolve(snapshot.val());
                        stateListener = true;
                    } else {
                        console.warn(`No data available for ${item} -> ${key}`);
                        resolve(null);
                    }
                }, (error) => {
                    console.error('Error fetching data:', error);
                    resolve(null);
                });
            });
        };

        const promises = Object.values(cantPanels.val).map((item, index) => {
            fetchedData[item] = {};
            return Promise.all([
                fetchData(item, 'Info').then(data => fetchedData[item].dataInfo = data),
                fetchData(item, 'Alarms/InternetConect').then(data => fetchedData[item].dataAlarms = data),
                fetchData(item, 'Fields').then(data => {
                    const dataFormat = formatWebData(data);
                    fetchedData[item].dataFields = dataFormat
                }),
            ]);
        });

        Promise.all(promises).then(() => {
            // console.warn(fetchedData)
            setDataFirebase(fetchedData);
            setLoadData(false);
        });

        return () => {
            listeners.forEach(unsubscribe => unsubscribe());
        };
    }, [cantPanels]);



    return <dataContext.Provider value={{ dataFirebase, dataUI, loadData, dataSelect, updateData, setDataSelect, getRegister }}>{children}</dataContext.Provider>;
}

