import LogoSvg from '../static/img/LogoIdeiperu.svg';
import { LabelStatus, InputForm, CheckBox, ButtonForm } from '../components/ui'
import { Link } from "react-router-dom";
import { useAuth } from '../context/authContext.js'
import useForm from "../components/hooks/useForm.js";
import PageWait from '../components/shared/PageWait.jsx';
import InputFormPassword from '../components/ui/InputFormPassword.jsx';

const initialForm = {
    Email: '',
    Password: '',
    stayLoggedIn: true,
}

const Login = () => {
    const { formState, onInputChange } = useForm(initialForm)
    const { login, updateError, error, loading } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await login(formState);
    }

    return (
        <>
            {loading ? (<PageWait />) : (
                <form className="bg-white flex flex-col text-center px-[3.6458%] py-[2.8%] rounded-2xl w-[90%] h-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] gap-8 shadow-xl sm:w-[80%] sm:px-8 sm:py-10 md:w-[60%] lg:w-[33%] xl:px-[3.6458%] 2xl:w-[27.34375%] max-[450px]:py-12 max-[650px]:py-12" onSubmit={handleSubmit}>
                    <img className="w-[82.78%] m-auto" src={LogoSvg} alt="LogoIdeiperu" />
                    <LabelStatus state={error.stateText} >{error.error && <p>{error.error}</p>}</LabelStatus>
                    <div className="flex flex-col text-center w-full  gap-[15px] ">
                        <InputForm
                            id="Email"
                            name="Email"
                            type="email"
                            placeholder=""
                            onChange={onInputChange}
                            required
                        >Ingrese su Correo</InputForm>
                        <InputFormPassword
                            id="Password"
                            name="Password"
                            type="password"
                            placeholder=""
                            onChange={onInputChange}
                            required
                            verificationPassword
                        >Ingrese su Contraseña</InputFormPassword>
                        <div className="w-full flex py-[5px] justify-between items-center">
                            <CheckBox
                                className="text-[#6B6B6B] font-Poppins text-xs w-full font-bold leading-5"
                                name="stayLoggedIn"
                                checked={formState.stayLoggedIn}
                                onChange={onInputChange}
                            >Permanecer Conectado</CheckBox>
                        </div>

                    </div>
                    <div className="flex items-center justify-around pb-10">
                        <ButtonForm tipo="Form" >INGRESAR</ButtonForm>
                    </div>
                    <div className="absolute bottom-5 right-5 flex justify-end items-center gap-4">
                        <Link
                            className="text-[#969696] font-Poppins text-md font-bold leading-5 underline underline-offset-[5px] cursor-pointer hover:text-[#B80D01] transition duration-200 focus:text-[#B80D01]"
                            to="/Register"
                            onClick={() => updateError({ error: 'Registre su nueva cuenta', stateText: 'normal' })}
                        >Crear una Cuenta</Link>
                    </div>
                </form>
            )

            }
        </>
    );
}

export default Login