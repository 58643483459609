import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import BackgroundStyle from './components/shared/BackgroundStylePage'
import { AuthProvider } from './context/authContext'
import { DataProvider } from './context/dataContext';
import { ProtectedRoute } from './services/ProtectedRoute'
import Paneles from './pages/Paneles';
import DashboardLayout from './layouts/DashboardLayout';
import PanelDetail from './pages/PanelDetail';
import Users from './pages/Users';
import Cruds from './pages/Cruds';
import Logout from './pages/Logout';

function App() {
  return (

    <BrowserRouter>
      <AuthProvider>
        <DataProvider>
          <Routes>
            <Route path='/' element={
              <ProtectedRoute>
                <BackgroundStyle>
                  <Login />
                </BackgroundStyle>
              </ProtectedRoute>
            } />
            <Route path='/Register' element={
              <ProtectedRoute>
                <BackgroundStyle>
                  <Register />
                </BackgroundStyle>
              </ProtectedRoute>
            } />
            <Route path='/Dashboard' element={
              <ProtectedRoute Pages>
                <DashboardLayout />
              </ProtectedRoute>
            }>
              <Route path='Usuarios' element={<Users />} />
              <Route path='Actividades' element={<Cruds />} />
              <Route path='Panel' element={<Paneles />} />
              <Route path='Panel/:ident' element={<PanelDetail />} />
              <Route path='' element={<Navigate to='/Dashboard/Panel' replace />} />
            </Route>
            <Route path='/logout' element={
              <Logout>
                <BackgroundStyle>
                </BackgroundStyle>
              </Logout>} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
        </DataProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
