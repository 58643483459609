import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyA8ZCjBTBGMm-CywBR4nkiLSiRSvPfpnzE",
  authDomain: "idei-blastpanel.firebaseapp.com",
  databaseURL: "https://idei-blastpanel-default-rtdb.firebaseio.com",
  projectId: "idei-blastpanel",
  storageBucket: "idei-blastpanel.appspot.com",
  messagingSenderId: "808819450470",
  appId: "1:808819450470:web:725e6209b543525e2d43a7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getDatabase(app)

export {app, auth, db}
