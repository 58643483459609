import { useCallback, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import { datetimeUTCPeru } from './helpers/utils'
import React from 'react'
import AlertMUI from '../components/ui_MUI/AlertMUI';
import { useData } from '../context/dataContext.js';
import TableOfTable from './TableOfTable.jsx';

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const TableUsers = () => {
    const [alert, setAlert] = useState([])
    const [data, setData] = useState([]);

    const columns = useMemo(() => [
        {
            accessorKey: 'user',
            header: 'Email',
        },
        {
            accessorKey: 'fechaRegistro',
            header: 'Fecha de Registro',
            Cell: ({ cell }) => (
                datetimeUTCPeru({ datetime: cell.getValue() })
            ),
        },
        {
            accessorKey: 'namePanel',
            header: 'PANEL',
        },
    ], []
    )

    const {
        data: fetchedUsers = [],
        isError: isLoadingUsersError,
        isFetching: isFetchingUsers,
        isLoading: isLoadingUsers,
    } = useGetUsers(setData);

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const table = useMaterialReactTable({
        columns,
        data: fetchedUsers,
        enableExpandAll: false,
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),
        renderDetailPanel: ({ row }) =>
            row.original.namePanel ? (
                <TableOfTable data={row.original}/>
            ) : null,
        initialState: {
            density: 'compact',
            sorting: [
                { id: 'fechaRegistro', desc: true },
            ],
        },
        createDisplayMode: "modal",
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingUsersError
            ? {
                color: "error",
                children: "Error al cargar los datos",
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: "500px",
            },
        },
        enableRowSelection: false,
        columnFilterDisplayMode: "popover",
        paginationDisplayMode: "pages",
        positionToolbarAlertBanner: "bottom",
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                }}
            >
                <Button
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                >
                    Exportar
                </Button>
            </Box>
        ),
        state: {
            isLoading: isLoadingUsers,
            showAlertBanner: isLoadingUsersError,
            showProgressBars: isFetchingUsers,
        },
    });


    const handleAlerts = useCallback((newAlerts) => {
        setAlert(newAlerts);
    }, [setAlert]);

    return (
        <>

            {alert.alert !== '' &&
                <AlertMUI Alerts={alert} hanldeAlerts={handleAlerts} />
            }
            <MaterialReactTable table={table} />
        </>
    )
}

function useGetUsers(setData) {
    const { getRegister } = useData();
    return useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const response = await getRegister();
            setData(response)
            return response;
        },
        refetchOnWindowFocus: false,
    });
}

const queryClient = new QueryClient();

const TableUsersWithProviders = () => (
    <QueryClientProvider client={queryClient}>
        <TableUsers />
    </QueryClientProvider>
);

export default TableUsersWithProviders;