import React from 'react'
import AccordionSN from "../components/ui_MUI/AccordionSN";
import { TableCellsIcon, UserGroupIcon, BookOpenIcon, PowerIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { TooltipHeader } from '../components/ui_MUI/TooltipHeader'
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { Link } from 'react-router-dom';

const bodyItems2 = [
    { key: 'Usuarios', icon: <UserGroupIcon />, label: 'Usuarios', route: 'Usuarios' },
    { key: 'Actividades', icon: <BookOpenIcon />, label: 'Actividad de usuarios', route: 'Actividades' }
];

export default function Drawer({ stateDrawer, handleDrawer, bodyItems, children, currentSection, loadData, user }) {

    return (
        <div className='flex-grow bg-[#eeeeee] relative'>
            <div className={`h-full z-10 absolute flex ${stateDrawer ? 'w-full' : 'w-fit'}`}>
                <div className={`h-full pt-24 bg-[#f0f0f0] flex flex-col transition-all duration-300 overflow-hidden ${stateDrawer ? 'w-[20rem]' : 'w-[4.5rem] max-[900px]:!w-0'}`}>
                    <div className="flex flex-col h-full justify-center ps-4 pb-4 gap-10 ">

                        <div className='text-[1.5rem] font-Poppins font-semibold text-[#2e354d] uppercase -mt-5 -mb-4 min-[500px]:hidden'>
                            {currentSection}
                        </div>
                        
                        <hr className="w-[95%] h-[2px] bg-[#cacaca] mb-[-1rem] -mt-4 min-[500px]:hidden" />
                        {stateDrawer ? (<>
                            {!loadData ? (
                                <AccordionSN
                                    icon={<TableCellsIcon />}
                                    label={'Paneles de Voladura'}
                                    bodyItems={bodyItems}
                                    currentSection={currentSection}
                                />
                            ) : (
                                <AccordionSN
                                    icon={<TableCellsIcon />}
                                    label={'Paneles de Voladura'}
                                    skeleton
                                />
                            )

                            }

                            {user.SuperUser &&
                                <>
                                    <hr className="w-[95%] h-[2px] bg-[#cacaca] mb-[-1.5rem] -mt-4" />
                                    <AccordionSN
                                        icon={<UserPlusIcon />}
                                        label={'Administrador'}
                                        bodyItems={bodyItems2}
                                        currentSection={currentSection}
                                    />
                                </>}
                            <div className="w-[19rem] -ms-2 ps-2 pe-3 flex flex-col flex-1 justify-end items-end gap-7">
                                <div className="w-full p-1 flex justify-start items-center gap-3 rounded-md">
                                    <UserCircleIcon className="!w-[2.5rem] !h-[2.5rem]" />
                                    <div className="flex flex-col gap-0">
                                        <p className="font-Poppins font-semibold">{user.email}</p>
                                        {user.SuperUser && <p className="font-Poppins font-normal">Administrador</p>}
                                    </div>
                                </div>
                                <Link className="w-full p-1 flex justify-start items-center gap-3 rounded-md cursor-pointer hover:bg-[#2e354d] hover:text-white" to='/logout'>
                                    <PowerIcon className="!w-[1.75rem] !h-[1.75rem]" />
                                    <p className="font-Poppins font-semibold">Cerrar Sesión</p>
                                </Link>

                            </div>
                        </>) : (<>
                            <TooltipHeader title="Paneles">
                                <TableCellsIcon
                                    className="w-[2.25rem] p-1 h-auto text-[#2e354d] cursor-pointer hover:bg-[#0000001b] rounded-md"
                                    aria-hidden="true"
                                    onClick={handleDrawer}
                                />
                            </TooltipHeader>
                            {user.SuperUser && <TooltipHeader title="Administrador">
                                <UserPlusIcon
                                    className="w-[2.25rem] p-1 h-auto text-[#2e354d] cursor-pointer hover:bg-[#0000001b] rounded-md"
                                    aria-hidden="true"
                                    onClick={handleDrawer}
                                />
                            </TooltipHeader>}
                            <div className="w-full -ms-2 ps-2 pe-3 flex flex-col flex-1 justify-end items-start gap-10">
                                <TooltipHeader title="Usuario">
                                    <UserCircleIcon
                                        className="w-[2.25rem] p-1 h-auto text-[#2e354d] cursor-pointer hover:bg-[#0000001b] rounded-md"
                                        aria-hidden="true"
                                        onClick={handleDrawer}
                                    />
                                </TooltipHeader>
                                <TooltipHeader title="Salir">
                                    <PowerIcon
                                        className="w-[2.25rem] p-1 h-auto text-[#2e354d] cursor-pointer hover:bg-[#0000001b] rounded-md"
                                        aria-hidden="true"
                                        onClick={handleDrawer}
                                    />
                                </TooltipHeader>

                            </div>

                        </>)}
                    </div>
                </div>
                {stateDrawer && <div className="flex-1 h-full bg-[#000000dd]" onClick={handleDrawer} />}

            </div>
            <div className="w-full h-full pt-[4rem] min-[900px]:ps-[4.5rem] pb-4 bg-[#f9f9f9] absolute overflow-y-auto" >
                {children}
            </div>
        </div>
    )
}
