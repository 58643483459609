import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

export default function AccordionSN({ icon, label, bodyItems, currentSection, defaultExpanded = true, skeleton = false }) {
    return (
        <>
            <Accordion
                className="!w-[18rem] !p-0 !m-0 !flex !flex-col !justify-center !items-start !shadow-none !gap-0 rounded-md cursor-pointer border-none me-2 !bg-[#f0f0f0] hover:bg-[#00000040] !mt-[-0.9rem] !static"
                sx={{
                    '& .MuiAccordion-heading': {
                        width: '100%',
                    },
                    '& .MuiCollapse-root': {
                        width: '100%',
                    }
                }}
                defaultExpanded={defaultExpanded}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="!flex-1" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="!h-[4rem] !w-full !p-0 !m-0 !flex "
                >
                    <div className="w-fit pe-3 flex justify-start items-center gap-3 rounded-md cursor-pointer">
                        {<icon.type className="!w-[2.25rem] p-1 h-auto text-[#2e354d]" />}
                        <p className="font-Poppins font-semibold">{label}</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails className='!pe-0 !pt-0'>
                    <div className="flex flex-col gap-4">

                        {!skeleton ? (
                            bodyItems.map((item, index) => (
                                currentSection === item.key ? (
                                    <div key={index} className={`w-full ps-4 p-1 pe-3 flex justify-start items-center gap-3 rounded-md  cursor-default bg-[#2e354dad]  text-white`}>
                                        <item.icon.type className="!w-[1.75rem] !h-[1.75rem]" />
                                        <p className="font-Poppins font-normal">{item.label}</p>
                                    </div>
                                ) : (
                                    <Link key={index} className={`w-full ps-4 p-1 pe-3 flex justify-start items-center gap-3 rounded-md hover:bg-[#2e354d] hover:text-white cursor-pointer`} to={item.route}>
                                        <item.icon.type className="!w-[1.75rem] !h-[1.75rem]" />
                                        <p className="font-Poppins font-normal">{item.label}</p>
                                    </Link>
                                )
                            ))
                        ) : (
                        <div className="w-full ps-4 p-1 pe-3 flex justify-start items-center gap-3 rounded-md cursor-pointer hover:bg-[#2e354d] hover:text-white" >
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="rectangular" width={210} height={60} />
                        </div>
                        )
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}
