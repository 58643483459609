import { Link } from 'react-router-dom';

export default function ButtonForm({ children, tipo, to, ...props }) {
  if (tipo === 'Link') {
    return (
      <Link
        className="group relative inline-block overflow-hidden border-black focus:outline-none focus:ring px-[20px] py-[10px] rounded-xl shadow-[2px_4px_4px_0px_rgba(0,0,0,0.5)] border-solid border-2 "
        to={to}
        {...props}
      >
        <span className="absolute inset-y-0 left-0 w-[0px] bg-[#2E354D] transition-all duration-300 group-hover:w-full group-active:bg-red"></span>

        <span className="relative font-medium text-xl transition-colors font-Poppins text-black leading-5 group-hover:text-white group-hover:text-2xl ">
          {children}
        </span>
      </Link>
    );
  } else if (tipo === 'Form') {
    return (
      <button className="group relative inline-block overflow-hidden focus:outline-none focus:ring px-[20px] py-[10px] rounded-xl bg-[#2E354D] border border-[#2E354D]">
        <span className="absolute inset-y-0 right-0 w-[0px] bg-[#ffffff] transition-all duration-300 group-hover:w-full group-active:bg-red"></span>

        <span className="relative text-xl font-medium transition-colors font-Poppins text-white leading-5 group-hover:text-[#2E354D]">
          {children}
        </span>
      </button>
    );
  }
}
