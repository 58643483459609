import React, { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useState } from "react";
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import Header from './Header';
import Drawer from './Drawer';
import { useData } from '../context/dataContext';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom';

const DashboardLayout = () => {
    const { user } = useAuth();
    const { dataFirebase, loadData, setDataSelect } = useData()
    const [stateDrawer, setstateDrawer] = useState(false)
    const [itemsPanel, setItemsPanel] = useState(false)
    const [currentSection, setCurrentSection] = useState('');
    const location = useLocation();
    const { ident } = useParams();
    const navigate = useNavigate();

    useEffect(() => { setstateDrawer(false); }, [location]);

    const handlePanelSection = useCallback(() => {
        if (!dataFirebase) return;

        if (ident) {
            const values = Object.values(dataFirebase);
            const selectedIndex = values.findIndex(item => item.dataInfo.Path === ident);
            const filterPath = selectedIndex !== -1 ? values[selectedIndex] : null;
            if (selectedIndex !== -1 && filterPath) {
                const updatedDataFields = {
                    ...filterPath.dataFields,
                    IndexData: selectedIndex,
                };
                setDataSelect(updatedDataFields)
                setCurrentSection(filterPath.dataInfo.Name);
            } else {
                navigate('/Dashboard/Panel');
            }
        } else {
            setCurrentSection('Paneles');
        }
    }, [ident, dataFirebase, navigate, setDataSelect]);

    useEffect(() => {
        const currentPath = location.pathname;

        if (currentPath.includes('Usuarios')) {
            setCurrentSection('Usuarios');
        } else if (currentPath.includes('Actividades')) {
            setCurrentSection('Actividades');
        } else if (currentPath.includes('Panel')) {
            handlePanelSection();
        } else {
            setCurrentSection('Dashboard');
        }
    }, [location, handlePanelSection]);


    const handleDrawer = () => { setstateDrawer(!stateDrawer) }

    useEffect(() => {
        if (dataFirebase) {
            const dataRepresentation = Object.values(dataFirebase).map((item) => ({
                key: item.dataInfo.Name,
                icon: <ViewQuiltOutlinedIcon />,
                label: item.dataInfo.Name,
                route: `Panel/${item.dataInfo.Path}`
            }))
            setItemsPanel(dataRepresentation)
        }
    }, [dataFirebase, currentSection]);

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header handleDrawer={handleDrawer} titulo={currentSection} />
                <Drawer
                    stateDrawer={stateDrawer}
                    handleDrawer={handleDrawer}
                    bodyItems={itemsPanel}
                    user={user}
                    dataFirebase={dataFirebase}
                    loadData={loadData}
                    currentSection={currentSection}
                >
                    <Outlet />
                </Drawer>
            </div>
        </>
    );
};

export default DashboardLayout;