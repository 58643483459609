
import { useEffect, useState } from 'react'
import { child, get, onValue, ref } from 'firebase/database'
import { db } from '../../firebase'
import { formatWebData } from '../helpers/formatWebData'
import { useAuth } from '../../context/authContext'

export const useFetchData = () => {
    const { user } = useAuth();
    const [mine, setMine] = useState('')
    const [dataRealtime, setDataRealtime] = useState(formatWebData())
    const [loadData, setLoadData] = useState(true)

    // const [state, setState] = useState({
    //     data: formatWebData(),
    //     isLoading: true,
    //     errors: null
    // })

    // const getFetch = async () => {
    //     try {
    //         get(child(ref(db), `users/${userId}`)).then((snapshot) => {
    //             if (snapshot.exists()) {
    //                 console.log(snapshot.val());
    //             } else {
    //                 console.log("No data available");
    //             }
    //         }).catch((error) => {
    //             console.error(error);
    //         });

    //         const listnerRef = ref(db, 'PV_Southern/Blasting_Panel/Address/0/Fields')
    //         onValue(listnerRef, (snapshot) => {
    //             if (snapshot.exists()) {
    //                 const datos = formatWebData(snapshot.val())
    //                 setState({
    //                     data: datos,
    //                     isLoading: false,
    //                     errors: null
    //                 })
    //             } else {
    //                 console.error("no existe database")
    //                 setState({
    //                     data: null,
    //                     isLoading: false,
    //                     errors: null
    //                 })
    //             }

    //         })

    //     } catch (error) {
    //         console.error(error)
    //         setState({
    //             data: null,
    //             isLoading: false,
    //             errors: null
    //         })
    //     }
    // }

    // useEffect(() => {
    //     getFetch()
    // }, [])

    useEffect(() => {
        if(user){
            // console.log(user);
            // const username = user.email.split('.com')[0];

            // get(child(ref(db), `AllowedUsers/${username}`)).then((snapshot) => {
            //     if (snapshot.exists()) {
            //         console.log(snapshot.val());
            //     } else {
            //         console.log("No data available");
            //     }
            // }).catch((error) => {
            //     console.error(error);
            // });
        }
    }, [user])

    return {dataRealtime, loadData}
}
