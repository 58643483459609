export default function BackgroundStyle({ children }) {
  return (
    <>
        <div className="w-full h-screen bg-[#EEEEEE]">
            <div className="w-full h-[82.51%] bg-gradient-to-r from-[#E5251B] to-[#B80D01]" style={{ clipPath: 'polygon(0 0, 100% 0, 100% 34.60%, 0 100%)' }}></div>
            
            <div className="absolute bottom-2 right-2 text-sm text-[#888888] max-[650px]:text-[7px] text-right">By signing in, you agree to IDEI's Conditions of Use & Sale. See our Privacy, Cookies, and Interest-Based Ads Notices.</div>
            {children}
        </div>
    </>
  )
}
