import { useEffect, useState } from "react";

export default function ButtonGroup({ children, handleButtonClick, indexSelect }) {
    const [activeIndex, setActiveIndex] = useState(indexSelect);

    useEffect(() => {
        setActiveIndex(indexSelect)
        handleButtonClick(indexSelect);
    }, [indexSelect,handleButtonClick])

    const handleChildClick = (index) => {
        setActiveIndex(index);
        handleButtonClick(index);
    };

    const childrenCount = Array.isArray(children) ? children.length : 1
    const childs = []
    if (childrenCount !== 1) {
        for (let i = 0; i < childrenCount; i++) {
            if (children[i].props.children[1]) {
                const isActive = activeIndex === i;
                const clase = isActive
                    ? "bg-gradient-to-b from-[#E5251B] to-[#B40D01] text-xs lg:text-sm font-Poppins flex-1 rounded-3xl text-white font-medium antialiased p-2 text-[#4D4D4D]"
                    : "text-md text-gray-500 font-medium rounded-3xl focus:text-[#B80D01] hover:text-[#B80D01] text-xs lg:text-sm font-Poppins flex-1 border p-2 border-[#DD2117]";
                childs.push(
                    <button
                        key={i}
                        className={clase}
                        type="button"
                        onClick={() => handleChildClick(i)}
                    >
                        {children[i]}
                    </button>
                );
            }
        }
    } else {
        const isActive = activeIndex === 0;
        const clase = isActive
            ? "bg-gradient-to-b from-[#E5251B] to-[#B40D01] text-xs lg:text-sm font-Poppins flex-1 rounded-3xl text-white font-medium antialiased p-2 text-[#4D4D4D]"
            : "text-md text-gray-500 font-medium rounded-3xl focus:text-[#B80D01] hover:text-[#B80D01] text-xs lg:text-sm font-Poppins flex-1 border p-2 border-[#DD2117]";
        childs.push(
            <button
                key={0}
                className={clase}
                type="button"
                onClick={() => handleChildClick(0)}
            >
                {children}
            </button>
        );
    }

    return (
        <div
            className="flex gap-5 rounded-lg"
        >
            {childs}
        </div>
    )
}