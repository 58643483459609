import { useCallback, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_EditActionButtons as EditActionButtons } from 'material-react-table';
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient, } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import { datetimeUTCPeru } from './helpers/utils'
import React from 'react'
import { useAuth } from "../context/authContext.js"
import AlertMUI from '../components/ui_MUI/AlertMUI';

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const TableUsers = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const [alert, setAlert] = useState([])
    const [data, setData] = useState([]);

    const columns = useMemo(() => [
        {
            accessorKey: 'ID',
            header: 'ID',
            size: 40,
            Edit: () => null
        },
        {
            accessorKey: 'username',
            header: 'Email',
            muiEditTextFieldProps: {
                type: 'email',
                required: true,
                error: !!validationErrors?.username,
                helperText: validationErrors?.username,
                onFocus: () => setValidationErrors({
                    ...validationErrors,
                    email: undefined,
                })
            },
        },
        {
            accessorKey: 'superuser',
            header: 'Super usuario',
            Cell: ({ cell }) => (cell.getValue() ? 'Si' : 'No'),
            editVariant: 'select',
            muiEditTextFieldProps: {
                required: true,
                SelectProps: {
                    native: true,
                },
                children: [
                    <option key="" value=""></option>,
                    <option key="true" value="true">Si</option>,
                    <option key="false" value="false">No</option>,
                ],
                error: !!validationErrors?.superuser,
                helperText: validationErrors?.superuser,
            }
        },
        {
            accessorKey: 'createdAt',
            header: 'Fecha de Creación',
            Cell: ({ cell }) => (
                datetimeUTCPeru({ datetime: cell.getValue() })
            ),
            Edit: () => null,
        },
        {
            accessorKey: 'registerAt',
            header: 'Fecha de Registro',
            Cell: ({ cell }) => (
                datetimeUTCPeru({ datetime: cell.getValue() })
            ),
            Edit: () => null,
        },
        {
            accessorKey: 'updatedAt',
            header: 'Fecha de Actualización',
            Cell: ({ cell }) => (
                datetimeUTCPeru({ datetime: cell.getValue() })
            ),
            size: 260,
            Edit: () => null,
        }
    ], [validationErrors]
    )

    /**
     * CRUD
     */

    //call CREATE hook
    const {
        mutateAsync: createUser,
        isPending: isCreatingUser
    } = useCreateUser(setAlert);

    //call READ hook
    const {
        data: fetchedUsers = [],
        isError: isLoadingUsersError,
        isFetching: isFetchingUsers,
        isLoading: isLoadingUsers,
    } = useGetUsers(setData);

    //call DELETE hook
    const {
        mutateAsync: deleteUser,
        isPending: isDeletingUser
    } = useDeleteUser();

    //CREATE action
    const handleCreateUser = async ({ values, table }) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        const response = await createUser(values);
        table.setCreatingRow(null); //exit creating mode
        setAlert(response)
    };

    //DELETE action
    const openDeleteConfirmModal = async (row) => {
        if (window.confirm('¿Esta seguro de eliminar este usuario?')) {
            const response = await deleteUser(row.original.ID)
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setAlert(response)
        }
    };

    /**
     * EXPORTS
     */
    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const table = useMaterialReactTable({
        columns,
        data: fetchedUsers,
        initialState: {
            density: 'compact',
        },
        createDisplayMode: "modal",
        editDisplayMode: "modal",
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingUsersError
            ? {
                color: "error",
                children: "Error al cargar los datos",
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: "500px",
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateUser,
        onEditingRowCancel: () => setValidationErrors({}),
        //
        renderCreateRowDialogContent: ({
            table,
            row,
            internalEditComponents,
        }) => (
            <>
                <DialogTitle variant="p">Crear nuevo usuario</DialogTitle>
                <DialogContent
                    sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                    {internalEditComponents.filter(
                        (value, key) => key !== 0 && key !== 5 && key !== 6
                    )}
                </DialogContent>
                <DialogActions>
                    <EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Eliminar">
                    <IconButton
                        color="error"
                        onClick={() => openDeleteConfirmModal(row)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        //
        enableRowSelection: false,
        columnFilterDisplayMode: "popover",
        paginationDisplayMode: "pages",
        positionToolbarAlertBanner: "bottom",
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                }}
            >
                <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                    startIcon={<PersonAdd />}
                >
                    Crear usuario
                </Button>
                <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                >
                    Exportar
                </Button>
            </Box>
        ),
        state: {
            isLoading: isLoadingUsers,
            isSaving: isCreatingUser || isDeletingUser,
            showAlertBanner: isLoadingUsersError,
            showProgressBars: isFetchingUsers,
        },
    });


    const handleAlerts = useCallback((newAlerts) => {
        setAlert(newAlerts);
    }, [setAlert]);

    return (
        <>

            {alert.alert !== '' &&
                <AlertMUI Alerts={alert} hanldeAlerts={handleAlerts} />
            }
            <MaterialReactTable table={table} />
        </>
    )
}

//CREATE hook (post new user to api)
function useCreateUser() {
    const { createAllowedUser } = useAuth();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (user) => {
            try {
                const response = await createAllowedUser(user);
                return response
            } catch (error) {
                console.error("salio el siguiente error: ", error)
                return [{ alert: 'Error', textAlert: `Comunicarse con soporte tecnico, falla del sistema` }]
            }

        },
        onMutate: async (newUserInfo) => {
            await queryClient.cancelQueries({ queryKey: ['users'] });

            const previousUsers = queryClient.getQueryData(['users']); // Obtén los datos actuales

            queryClient.setQueryData(['users'], (prevUsers) => [
                ...(prevUsers || []),
                {
                    ...newUserInfo,
                    id: (Math.random() + 1).toString(36).substring(7),
                },
            ]);

            return { previousUsers };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['users'] });
            await queryClient.refetchQueries({ queryKey: ['users'] });
        },
    });
}

//READ hook (get users from api)
function useGetUsers(setData) {
    const { getUsers } = useAuth();
    return useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const response = await getUsers();
            setData(response)
            return response;
        },
        refetchOnWindowFocus: false,
    });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
    const { deleteUser } = useAuth()
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (idUser) => {
            try {
                const response = await deleteUser(idUser)
                return response
            } catch (error) {
                console.error("salio el siguiente error: ", error)
                return [{ alert: 'Error', textAlert: `Comunicarse con soporte tecnico, falla del sistema` }]
            }
        },
        onMutate: (userId) => {
            queryClient.setQueryData(['users'], (prevUsers) =>
                prevUsers?.filter((user) => user.id !== userId),
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
    });
}

const queryClient = new QueryClient();

const TableUsersWithProviders = () => (
    //Put this with your other react-query providers near root of your app
    <QueryClientProvider client={queryClient}>
        <TableUsers />
    </QueryClientProvider>
);

export default TableUsersWithProviders;

const validateRequiredSelect = (value) => {
    return value === "" ? true : false
};

const validateEmail = (email) => !!email.length && email
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateUser(user) {
    return {
        username: !validateEmail(user.username) ? 'Ingrese un email valido' : '',
        superuser: validateRequiredSelect(user.superuser) ? 'Seleccione una opción' : '',
    };
}
