import { useAuth } from "../context/authContext";
import { Navigate } from 'react-router-dom'
import PageWait from "../components/shared/PageWait";
import React from "react";

export function ProtectedRoute({ children, Pages=false }) {
    const { user, loading } = useAuth();
    if(loading){
        return (<>  
            <PageWait/>
        </>)
    }
    
    if (!user && Pages) {
        return <Navigate to='/' />;
    }

    if (user && !Pages ) {
        return <Navigate to='/Dashboard/Panel' />;
    }

    return <div>{children}</div>;
}
