
import Skeleton from '@mui/material/Skeleton';

export default function P10Skeleton({ p10, right, top, children, name, ...props }) {
    const [rowP10, colP10] = p10.split('x').map(part => parseInt(part.trim()));

    const aspect = `${colP10 * 32}/${rowP10 * 16}`
    const width = Number(((colP10 * 32) / 4.2).toFixed(2))
    const widthClass = `${width}%`

    return (
        <Skeleton
            variant="rectangular"
            sx={{
                position: 'absolute',
                width: widthClass,
                right: `${parseFloat(right)}%`,
                top: `${parseFloat(top)}%`,
                aspectRatio: aspect,

            }}
        />
    );
}
