import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/authContext.js'
import PageWait from '../components/shared/PageWait.jsx';
import { useEffect } from "react";

const Logout = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            if (user) {
                await logout();
            }
            window.location.href = '/';
        };
        handleLogout();
    }, [user, logout, navigate]);
    return (
        <PageWait></PageWait>
    );
}

export default Logout